import "./App.scss"
import Router from "./page/router/Router";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import React from "react";
import { AuthProvider } from "./components/authContext/AuthContext";

function App() {

    return (
        <div className="wrapper">
            <div className="containWrapper">
                <AuthProvider>
                    <Header></Header>
                    <div className="headMargin"></div>
                    <Router></Router>
                </AuthProvider>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default App;

import style from "./Footer.module.scss";
import React from "react";
import { Link } from "react-router-dom";
import StarBanking from "../StarBanking/StarBanking";

const FooterPC = () => {
  return (
    <div className={style.footer}>
      <div className={style.wrapper}>
        <div className={style.infos}>
          <h1 className={style.title}>매직클럽 코리아</h1>
          <section className={style.section}>
            <p className={style.info}>서울시 종로구 평창 12길 20-13 201호</p>|
            <Link to="/Rule">매직클럽 회칙 전문 &gt; </Link>
          </section>
          <section className={style.section}>
            <p>대표자 : 이원재</p>|<p>사업자 등록 번호 : 769-12-02416</p>|
            <p>연락처 : 010-6324-6912</p>
          </section>
          <p className={style.copyright}>
            Copyright© 2024 MAGIC CLUB KOREA. ALL RIGHTS RESERVED
          </p>
        </div>
        <div className={style.auths}>
          <StarBanking />
          <img src="/img/MainLogoLong.png" alt="" className={style.img} />
        </div>
      </div>
    </div>
  );
};

export default FooterPC;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/authContext/AuthContext";
import style from "./EventRegister.module.scss";

interface EventApplyResponse {
    data: {
        token: string;
    };
}
interface Event {
    title: string;
    place: string;
    evented_at: string;
    finished_at: string;
    money: number;
    context: string | null;
    img_url: string;
    all_amount: number;
    early_bird_amount: number;
    early_bird_at: string;
    rounds: { id: number; time: string; }[];
    registered_users: number;
    close_min: number;
    performers: string;
}

interface interUserInfo {
    result: {
        resultCode: number;
        resultDescription: string;
        resultMessage: string;
    };
    body: {
        id: number;
        name: string;
        email: string;
        phone_number: string;
        age: number;
        user_state: string;
    }
}

const EventRegister: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { isLogin } = useAuth();
    const [userInfo, setUserInfo] = useState<interUserInfo | null>(null);
    const [event, setEvent] = useState<Event | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedRounds, setSelectedRounds] = useState<string>("");
    const [selectedRoundId, setSelectedRoundId] = useState<number | null>(null); // 라운드 ID 추가
    const [isRoundSet, setIsRoundSet] = useState<boolean>(false);
    const [paymentMethod, setPaymentMethod] = useState<number>(0);
    const [reservationCount] = useState<number>(1); // 인원 수를 1로 고정
    const [agree, setAgree] = useState<boolean>(false);
    const [totalAmount, setTotalAmount] = useState<number | null>(null); // 총 결제 금액 상태 추가
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const API = process.env.REACT_APP_API;
                const response = await axios.get<{ result: any, body: any[] }>(`${API}/open-api/event/${id}`);
                const event = {
                    ...response.data.body[0],
                    rounds: response.data.body.map((round) => ({
                        id: round.show_id,
                        time: new Date(round.evented_at).toLocaleString() + ' ~ ' + new Date(round.finished_at).toLocaleString(),
                    })),
                };
                console.log(`event : ${event.rounds.length}`)
                setEvent(event);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch event');
                setLoading(false);
            }
        };

        fetchEvent();
    }, [id]);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const API = process.env.REACT_APP_API;
                const response = await axios.get<interUserInfo>(`${API}/api/user/me`, {
                    withCredentials: true,
                });
                if (response.status === 200) {
                    setUserInfo(response.data);
                }
            } catch (err) {
                console.log("Failed to get user info");
            }
        }

        fetchUserInfo();
    }, []);

    const goToLogin = () => {
        navigate('/login');
    };

    const handleAgreeChange = () => {
        setAgree(!agree);
    };

    const handleSeleted = (roundTxt: string, roundId: number) => {
        if(!event){
            return;
        }
        console.log(event)
        setSelectedRounds(roundTxt);
        setSelectedRoundId(roundId);
        setIsRoundSet(true);

        let amount = event.money ?? 0;

        if (roundId === 0 && event.rounds.length !== 1) {
            amount = event.all_amount ?? 0;
        }

        if(new Date() < new Date(event.early_bird_at)){
            amount = amount / 100 * event.early_bird_amount;
        }

        setTotalAmount(amount);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!event) {
        return <div>Event not found</div>;
    }

    const hasEventStarted = new Date(event.evented_at) <= new Date();

    const APIEventRegister = async () => {
        if (userInfo) {
            try {
                const API = process.env.REACT_APP_API;
                const response = await axios.post<EventApplyResponse>(`${API}/api/user-event/register`,
                    {
                        event_id: id,
                        show_number: selectedRoundId,
                        names: userInfo.body.name,
                    },
                    { withCredentials: true }
                )
                if (response.status === 200) {
                    alert("이벤트 신청이 완료되었습니다.");
                    navigate('/events');
                }
                else if (response.status === 400 || response.status === 500) {
                    alert("이벤트 신청에 오류가 있습니다. 관리자에게 문의해 주세요.");
                }
            } catch (err) {
                alert("이벤트 신청에 오류가 있습니다. 관리자에게 문의해 주세요.");
                console.log("Event Register failed");
            }
        }
        else {
            console.log("로그인 정보가 없음");
        }
    };

    const formatMoney = (amount: number) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const sortedRounds = event.rounds.sort((a, b) => (a.id === 0 ? 1 : b.id === 0 ? -1 : a.id - b.id));

    const formatRoundTime = (time: string) => {
        let startTime = time.split("~")[0].trim();
        const isPM = startTime.includes("오후");

        let [datePart, timePart] = startTime.split(' 오');
        timePart = timePart.replace(/전|후/, '').trim();
        let [hours, minutes, seconds] = timePart.split(':').map(Number);

        if (isPM && hours < 12) {
            hours += 12;
        } else if (!isPM && hours === 12) {
            hours = 0;
        }

        return `${hours}시`;
    };

    return (
        <>
            <div className={style.eventRegister}>
                <h1>이벤트 예약하기</h1>
                <div className={style.gridContainer}>
                    <div className={`${style.gridItem} ${style.gridItemLabel}`}>
                        선택한 이벤트
                    </div>
                    <div className={style.gridItem}>
                        <div className={style.eventDetailContainer}>
                            <div className={style.eventImageContainer}>
                                <img src={`/img/${event.img_url}`} alt={event.title} className={style.eventImage} />
                            </div>
                            <div className={style.eventInfo}>
                                <h2>{event.title}</h2>
                                <p><strong>장소: </strong>{event.place}</p>
                                <p><strong>날짜: </strong>{new Date(event.evented_at).toLocaleDateString()}</p>
                                <p><strong>출연자: </strong>{event.performers}</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.gridItem} ${style.gridItemLabel}`}>
                        관람 시간
                    </div>
                    <div className={style.gridItem}>
                        <div className={style.optionContainer}>
                            <div className={style.rounds}>
                                <h3>관람 시간을 선택해 주세요.</h3>
                                {sortedRounds.map((round) => (
                                    <label key={round.id}>
                                        <input
                                            type="radio"
                                            name="round"
                                            value={round.id.toString()}
                                            onChange={(e) => {
                                                const labelText =
                                                    round.id === 1
                                                        ? `Day time (${formatRoundTime(round.time)})`
                                                        : round.id === 2
                                                            ? `Night time (${formatRoundTime(round.time)})`
                                                            : `All time - 전 회차 (${formatRoundTime(round.time)})`;
                                                handleSeleted(labelText, round.id);
                                            }}
                                        />
                                        {round.id === 1 && `Day time (${formatRoundTime(round.time)})`}
                                        {round.id === 2 && `Night time (${formatRoundTime(round.time)})`}
                                        {round.id === 0 && `All time - 전 회차 (${formatRoundTime(round.time)})`}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.buttonPlaceholder}></div>

                <div className={style.detailAndPaymentContainer}>
                    <div className={style.gridRow}>
                        <div className={`${style.gridCell} ${style.labelCell} ${style.gridTopCell}`}>관람 시간</div>
                        <div className={`${style.gridCell} ${style.labelCell} ${style.gridTopCell}`}>예약 인원</div>
                        <div className={`${style.gridCell} ${style.labelCell} ${style.gridTopCell}`}>총 결제금액</div>
                    </div>
                    <div className={style.gridRow}>
                        <div className={style.gridCell}>{selectedRounds ? selectedRounds : "선택하세요"}</div>
                        <div className={style.gridCell}>1</div>
                        <div className={style.gridCell}>{totalAmount} 원</div>
                    </div>
                    <div className={style.gridRow}>
                        <div className={`${style.gridCell} ${style.labelCell}`}>결제 방식</div>
                        <div className={style.gridCell} style={{ gridColumn: 'span 2', padding: 0 }}>
                            <div className={style.paymentOptions}>
                                <label>
                                    <input type="radio" name="payment" value="transfer" onChange={(e) => setPaymentMethod(1)} />
                                    무통장 입금
                                </label>
                                <label>
                                    <input type="radio" name="payment" value="tosspay" onChange={(e) => setPaymentMethod(2)} disabled />
                                    토스 페이
                                </label>
                                <label>
                                    <input type="radio" name="payment" value="kakaopay" onChange={(e) => setPaymentMethod(3)} disabled />
                                    카카오 페이
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.registrationForm}>
                    <div className={style.divider}></div>

                    <div className={style.agreement}>
                        <div style={{display: `${paymentMethod == 1 ? "block":"none"}`}} className={style.payAccount}>
                            무통장 입금 주소 :<br />
                            국민은행 050701-04-138161<br />
                            예금주 매직클럽 코리아 (이원재)
                        </div>
                        환불 규정은 아래와 같습니다.
                        <div className={style.agreementTab}>
                            (4일 전): 100% 환불
                            <br />
                            (3일 전): 50% 환불
                            <br />
                            (2일 전): 취소 불가
                            <br />
                            단, 당일 예매자들은 100% 환불
                        </div>
                    </div>

                    <div className={style.agreement}>
                        <label>
                            <input
                                type="checkbox"
                                checked={agree}
                                onChange={handleAgreeChange}
                            />
                            위 안내사항을 읽고 숙지했으며, 동의합니다.
                        </label>
                    </div>

                    <button
                        type="submit"
                        className={style.submitButton}
                        disabled={!isLogin || !(agree && isRoundSet && selectedRounds && paymentMethod != 0)}
                        onClick={isLogin ? APIEventRegister : goToLogin}
                    >
                        {isLogin ? "예약하기" : "로그인이 필요합니다"}
                    </button>
                </div>
            </div>
        </>
    );
};

export default EventRegister;

import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import axios from "axios";

interface AuthContextType {
    isLogin: boolean;
    login: () => void;
    logout: () => void;
}
interface InfoResponse {
    body: {
      email: string;
      name: string;
    };
    result: string;
}
interface Props {
  children: ReactNode
}


const API = process.env.REACT_APP_API;
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: Props) => {
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        const getInfo = axios.get<InfoResponse>(`${API}/api/user/me`, {
          withCredentials: true,
        });
        console.log(getInfo);
        getInfo
          .then((data) => {
            console.log(data);
            if (data.status == 200) {
              setIsLogin(true);
            }
          })
          .catch((e) => {
            setIsLogin(false);
          });
      }, []);
    
      const login = () => {
        setIsLogin(true);
      };
    
      const logout = () => {
        setIsLogin(false);
      };

  return (
    <AuthContext.Provider value={{ isLogin, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
      throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
  };
  
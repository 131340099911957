import React, { useState } from "react";
import Archive from "../../components/Archive/Archive";
import InfoEdit from "../../components/InfoEdit/InfoEdit";
import style from "./MyPage.module.scss";

const MyPage = () => {
  const [isInfoEdit, setIsInfoEdit] = useState(false);

  const showInfoEdit = (e: React.MouseEvent<HTMLElement>) => {
    setIsInfoEdit(true);
  };

  const showArchive = (e: React.MouseEvent<HTMLElement>) => {
    setIsInfoEdit(false);
  };

  return (
    <div className={style.mypage}>
      <h1 className={style.mypage__title}>My Page</h1>
      <ul className={style.nav}>
        <li className={style.list}>
          <button
            type="button"
            onClick={showArchive}
            className={`${style.btn} ${!isInfoEdit && style.highlight}`}
          >
            이벤트 내역
          </button>
        </li>
        <hr />
        <li className={style.list}>
          <button
            type="button"
            onClick={showInfoEdit}
            className={`${style.btn} ${isInfoEdit && style.highlight}`}
          >
            개인 정보 수정
          </button>
        </li>
      </ul>
      <main className={style.main}>
        {isInfoEdit ? <InfoEdit /> : <Archive />}
      </main>
    </div>
  );
};

export default MyPage;

import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "./KakaoTest.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/authContext/AuthContext";

const API = process.env.REACT_APP_API;

interface InfoResponse {
  body: {
    email: string;
    name: string;
    user_state: string;
  };
  result: string;
}

interface EventList {
  body: Event[];
  result: string;
}

interface Event {
  names: string;
  phone_number: string;
  email: string;
}

const KakaoTest = () => {
  const navigate = useNavigate();
  const [isLend, setIsLend] = useState(false);
  const [eventList, setEventList] = useState<Event[]>([]);
  const { id, num } = useParams<{ id: string, num: string }>();
  const { login } = useAuth();

  const Kakao = window.Kakao;
  const KAKAO = process.env.REACT_APP_KAKAO;
  const API = process.env.REACT_APP_API;
  const DOMAIN = process.env.REACT_APP_DOMAIN;

  useEffect(() => {
    if(!Kakao.isInitialized()){
      Kakao.init(KAKAO);
    }
  }, []);

  function _login(){
    if(window.Kakao){
      Kakao.Auth.authorize({
        redirectUri: `${DOMAIN}/login`
      });
    }
  }

  const handleCallback = async () => {
    // URLSearchParams를 사용하여 URL에서 쿼리 파라미터 추출
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log(code);

    if (code) {
      try {
        console.log(code)
        // 백엔드로 인가 코드를 전달하여 로그인 처리
        const response = await axios.post(`${API}/open-api/oauth`, {
          code
        }, { withCredentials: true });

        console.log(response)

        if (response.data.result.resultCode == 200) {
          alert('로그인 성공');
          login();
          navigate('/'); // 메인 페이지로 이동
        } else {
          alert('로그인 실패: ' + response.data.message);
        }
      } catch (error) {
        console.error('로그인 오류:', error);
        alert('로그인 중 오류가 발생했습니다.');
      }
    }
  };

  useEffect(() => {
    handleCallback();
  }, []);

  return (
    <div className={style.login}>
      <div className="no">
        <button className={style.kakao} onClick={_login}></button>
      </div>
      <button className={style.btnKakao} type="button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon icon--logo">
          <path d="M11.9982 4C6.61144 4 2.245 7.4427 2.245 11.69C2.245 14.436 4.07091 16.848 6.81682 18.206C6.66771 18.7208 5.8565 21.5202 5.82462 21.7406C5.82462 21.7406 5.80492 21.9056 5.91183 21.9684C5.9476 21.9865 5.98668 21.997 6.02667 21.9995C6.06666 22.0019 6.10673 21.9961 6.14441 21.9825C6.45201 21.9394 9.70809 19.6558 10.2708 19.2629C10.8431 19.3423 11.4204 19.3815 11.9982 19.3801C17.385 19.3801 21.7515 15.9374 21.7515 11.69C21.7515 7.4427 17.385 4 11.9982 4Z" fill="black" fill-opacity="0.9"></path>
        </svg>
        카카오로 1초 만에 시작하기
      </button>
    </div>
  );
};

export default KakaoTest;

import React from "react";
import style from "./Rule.module.scss";

const Rule = () => {
  return (
    <div className={style.rule}>
      <h1 className={style.title}>매직클럽 회칙</h1>
      <main className={style.main}>
        <h2>제1장 총칙</h2>
        <h3>제1조&#40;명칭&#41;</h3>
        <p>
          ① 본회의 정식 명칭은 &lt;매직 클럽 코리아&gt; 이라 칭한다.
          <br />
          ② 본회의 정식 영문 명칭은 &lt;Magic Club Korea&gt; 이라 칭한다.
          <br />
          ③ 본회의 명칭을 서류상의 기재 목적 혹은 홍보 목적으로 &lt;Magic
          Club&gt; 또는 &lt;매직 클럽&gt; 이라고 줄일 수 있다. <br />
        </p>
        <h3>제2조&#40;목적&#41;</h3>
        <p>
          ① 본회는 마술이란 공통된 주제를 통해 회원 상호간의 발전 및 친분을
          도모하며 마술에 대한 이론 및 실전, 연습을 바탕으로 공연과 워크샵 등
          친목 활동 등 온라인 및 오프라인의 다양한 활동을 하는 것을 목적으로
          한다.
        </p>
        <h3>제3조&#40;운영&#41;</h3>
        <p>
          ① 본회는 회원에 의한 민주성과 자주성의 원칙 아래 회칙에 의거하여
          운영한다.
          <br />② 본 회칙은 본회 공식 매체에 공지하여 모든 회원들이 명확히
          이해하고 상시 열람할 수 있도록 한다.
        </p>
        <h3>제4조&#40;기구&#41;</h3>
        <p>
          ① 다음 각 호를 본회의 의결기구로 둔다. 하위 의결기구에서 의결할 수
          있도록 한 사항은 상위 의결기구에서도 의결할 수 있다.
          <br />
          <br />
          1. 본회의 일상적 의결기구로 회장을 둔다.
          <br />
          2. 본회의 실무적 최고의결기구로 집행부 총회를 둔다.
          <br />
          <br />
          ② 다음 각 호를 본회의 집행기구로 둔다.
          <br />
          <br />
          1. 본회의 일상적 집행기구로 회장을 둔다.
          <br />
          2. 본회의 최고집행기구로 집행부를 둔다.
        </p>
        <h3>제5조&#40;적용&#41;</h3>
        <p>
          ① 본 회칙에 명시되지 않은 사항에 대해서는 의결기구의 의결을 따른다.
          <br />② 제1항에도 불구하고 결정할 수 없는 사항에 대해서는 일반 관례를
          따른다.
        </p>
        <h2>제2장 회원</h2>
        <h3>제6조&#40;회원의 구성&#41;</h3>
        <p>① 본회의 회원은 준회원과 정회원으로 구성된다.</p>
        <h3>제7조&#40;회원의 자격&#41;</h3>
        <p>
          ① 다음 각 호의 조건을 모두 만족하는 자를 본회의 신입 회원으로 한다.
          <br />
          <br />
          1. 마술에 관심이 있는 만18세 이상 성인인 자<br />
          2. 집행부에서 안내한 절차에 따라 본회 가입을 신청한 자<br />
          <br />
          ② 다음 각 호의 조건 중 하나 이상을 만족하는 자를 본회의 정회원으로
          한다.
          <br />
          <br />
          1. 준회원의 자격으로 연회비를 납부한 지 1년이 지나지 않은 자<br />
          2. 직전 연도에 정회원이었으며, 다시 연회비를 납부하여 정회원 자격을
          갱신한 지 1년이 지나지 않은 자
        </p>
        <h3>제8조&#40;회원의 권리와 의무&#41;</h3>
        <p>
          ① 본회 회원은 다음 각 호의 권리를 갖는다.
          <br />
          <br />
          1. 본회의 제반 활동에 대한 민원을 제기하거나 의견을 자유롭게 개진할
          권리
          <br />
          2. 본회 정기모임 및 내부 행사에 참여할 권리
          <br />
          <br />
          ② 본회 정회원은 다음 각 호의 권리를 갖는다.
          <br />
          <br />
          1. 본회 내부 행사 참여 시 집행부로부터 금전적인 할인 혜택을 받을 권리
          <br />
          2. 행사 과정 혹은 본회 웹사이트에서 준회원에게 공개되지 않는 내용을
          추가로 제공받을 권리
          <br />
          <br />
          ③ 본회 회원은 다음 각 호의 의무를 지닌다.
          <br />
          <br />
          1. 본회 회칙과 공지를 숙지하고 준수할 의무
          <br />
          2. 본회 의결기구가 민주적으로 결정한 사항을 준수하고 이행할 의무
          <br />
          3. 본회의 대외비를 보장하고 수호할 의무
          <br />
          4. 본회 웹사이트를 건강하게 이용할 의무
          <br />
          5. 상업적 목적, 종교적 목적, 마술과 친목 이외의 목표로 활동하지 않을
          의무
          <br />
          <br />④ 본회는 본조에 명시된 의무를 다하지 않은 회원에 대하여 징계를
          부과할 수 있다.
        </p>
        <h2>제3장 의결 기구</h2>
        <h3 className={style.jul}>제1절 통칙</h3>
        <h3>제9조&#40;의장&#41;</h3>
        <p>
          ① 의결기구의 의장은 본회 회장이 맡는다.
          <br /> 단, 회장의 유고 또는 궐위 시, 혹은 회장에 대한 탄핵안이 상정된
          의결 기구의 경우 부회장이 의장을 맡는다.
          <br />② 제1항을 이행하기 어려울 경우에는 회의 참여자들의 합의를 통해
          다른 집행부원이 의장을 맡는다.
        </p>
        <h3>제10조&#40;회의장&#41;</h3>
        <p>
          ① 회의장은 모든 회의 참여자들이 접근할 수 있는 장소여야 한다.
          <br />
          ② 제1항에도 불구하고 의장이 대면 회의가 어렵다고 판단할 경우, 다음 각
          호의 방식을 이용할 수 있다.
          <br />
          <br />
          1. Zoom 등 화상 원격 회의
          <br />
          2. 카카오톡 등 메신저를 이용한 회의
          <br />
          3. 회의록 혹은 연서 등을 이용한 서면 회의
          <br />
          4. 온라인 투표 매체를 이용한 방식
        </p>
        <h3>제11조&#40;소집&#41;</h3>
        <p>
          ① 한 개 이상의 안건이 발생하거나 발의된 경우, 의장은 의결기구를 소집할
          수 있다.
          <br />
          ② 재적 집행부원 2/3 이상의 연서를 통해 집행부원의 탄핵안을 집행부
          총회로 발의할 수 있으며, 이때 의장은 의결기구를 소집하여야 한다.
          <br />
          ③ 의장은 의결기구 개회 3일 이전에 일시, 안건, 회의장을 공식 게시
          매체를 통해 공지해야 한다.
          <br />④ 의장이 긴급하다고 판단하는 안건이 있는 경우, 의장은 긴급
          의결기구를 소집할 수 있으며, <br />
          개회 1일 이전에 일시, 안건, 회의장을 공식 게시 매체를 통해 공지해야
          한다.
        </p>
        <h3>제12조&#40;의결권 박탈&#41;</h3>
        <p>
          ① 탄핵안 혹은 징계안의 대상자는 해당 안건에서의 의결권을 박탈한다.
        </p>
        <h3>제13조&#40;결과 공고&#41;</h3>
        <p>
          ① 본회는 의결기구 진행 시 회의 내용을 기록하고, 회의가 끝난 뒤 7일
          이내에 회의 결과를 공식 게시 매체를 통해 공고해야 한다.
        </p>
        <h3 className={style.jul}>제2절 집행부 총회</h3>
        <h3>제14조&#40;지위&#41;</h3>
        <p>① 집행부 총회는 본회의 실무적 최고의결기구이다.</p>
        <h3>제15조&#40;권한&#41;</h3>
        <p>
          ① 집행부 총회는 다음 각 호의 사항을 심의, 의결한다.
          <br />
          <br />
          1. 본회의 전반적인 활동에 관한 사항
          <br />
          2. 본회 회원에 관한 사항
          <br />
          3. 본회 회칙에 관한 사항 및 회칙 개정
          <br />
          4. 본회 재정에 관한 사항
          <br />
          5. 집행부원 탄핵 및 회원 징계에 관한 사항
          <br />
          6. 기타 본회 운영에 관한 중대한 사항
        </p>
        <h3>제16조&#40;구성&#41;</h3>
        <p>① 집행부 총회는 본회 집행부원으로 구성한다.</p>
        <h3> 제17조&#40;정기 집행부 총회&#41;</h3>
        <p>
          ① 의장은 반기 당 두 번의 집행부 총회를 소집하는 것을 원칙으로 한다.
        </p>
        <h3>제18조&#40;의결&#41;</h3>
        <p>
          ① 재적 집행부원 1/2 이상의 출석과 재석 집행부원 1인 이하의 반대 혹은
          기권으로 의결한다.
          <br />② 한번 의결된 안건에 대한 재투표는 집행부원 1/2 이상의 동의로
          실시한다.
        </p>
        <h2>제4장 회장</h2>
        <h3>제19조&#40;지위&#41;</h3>
        <p>
          ① 매직 클럽 코리아 회장&#40;이하 회장&#41;은 본회를 대표한다.
          <br />② 회장은 본회의 일상적인 의결기구이자 집행기구로, 평시 본회
          업무의 총책임자이자 최종책임자가 된다.
        </p>
        <h3>제20조&#40;책임&#41;</h3>
        <p>
          ① 회장은 다음 각 호의 책임을 지닌다.
          <br />
          <br />
          1. 본회 목적에 맞는 활동을 진행할 책임
          <br />
          2. 건전하고 바람직한 문화 형성을 보장할 책임
          <br />
          3. 본회를 민주적으로 운영할 책임
        </p>
        <h3>제21조&#40;업무&#41;</h3>
        <p>
          ① 회장은 다음 각 호의 업무를 수행한다.
          <br />
          <br />
          1. 의결기구의 의장직 수행
          <br />
          2. 집행부의 기구장직 수행
          <br />
          3. 본회 행사 기획 및 운영
          <br />
          4. 본회와 관련한 금전 거래의 승인과 최종 책임
          <br />
          5. 본회 공식 매체의 운영, 게시 및 삭제
          <br />
          6. 본회 모임의 최종 허가
          <br />
          7. 기타 본회의 일상적 업무
        </p>
        <h3>제22조&#40;임기&#41;</h3>
        <p>
          ① 회장의 임기는 당선일로부터 인수인계 기간을 포함하여 다음 회장 취임
          전일까지로 한다.
        </p>
        <h2>제5장 집행부</h2>
        <h3>제23조&#40;지위&#41;</h3>
        <p>① 집행부는 본회의 최고집행기구이다.</p>
        <h3> 제24조&#40;구성&#41;</h3>
        <p>
          ① 집행부는 다음 각 호의 인원으로 구성한다.
          <br />
          <br />
          1. 회장 : 집행부의 장<br />
          2. 부회장 : 회장의 전반적인 업무 보조, 회장의 업무 수행 불능 시
          대리권자
          <br />
          3. 총무 : 모임 및 행사의 수익, 지출 파악과 공금 관리
          <br />
          4. 운영기술부장 : 공식 매체 관리와 내부 연락망 관리
          <br />
          5. 운영사무부장 : 회원 정보 관리 및 민원 정리, 징계 공고
          <br />
          6. 기획부장 : 행사 및 모임의 기획과 진행
          <br />
          <br />
          ② 회장 재량에 따라 위 인원은 격임이 가능하다.
          <br />
          ③ 집행부에 포함되지 않지만 집행부 업무를 보조하는 보조부원을 집행부
          재량에 따라 선발하여 둘 수 있다.
          <br />
        </p>
        <h3>제25조&#40;책임&#41;</h3>
        <p>
          ① 집행부는 동아리의 전반적인 운영을 책임지며, 솔선수범하여 회원들에게
          모범을 보인다.
        </p>
        <h3>제26조&#40;권한 및 업무&#41;</h3>
        <p>
          ① 집행부 내 업무의 분배는 집행부원들의 자율적이고 민주적인 논의 하에
          결정한다.
          <br />
          ② 집행부의 권한 및 업무는 다음 각 호와 같다.
          <br />
          <br />
          1. 본회의 대외적인 행사 참여 시 회장을 보좌하거나, 대표자 직을 대리
          <br />
          2. 비상대책위원회 구성 및 비상대책위원 인준
          <br />
          3. 본회 회계 내역 열람 및 수정
          <br />
          4. 기타 본회의 중대한 업무
        </p>
        <h3>제27조&#40;선발&#41;</h3>
        <p>
          ① 어떤 직책의 차기 집행부원은 다음 각 호의 조건을 모두 만족한 자로
          선발한다.
          <br />
          <br />
          1. 해당 직책을 수행한 집행부원이 추천한 자<br />
          2. 해당 직책을 수행한 집행부원을 제외한 타 집행부원의 만장일치
          찬성으로 인준한 자
        </p>
        <h3>제28조&#40;임기&#41;</h3>
        <p>
          ① 집행부원의 임기는 인준일로부터 인수인계 기간을 포함하여 다음
          집행부원 취임 전일까지로 한다.
          <br />
          ② 집행부원이 궐위 및 사고로 집행부 임기를 마치지 못하고 사퇴할 경우,
          <br />
          해당 집행부원의 임기는 즉시 종료되며 제27조제1항을 통해 해당 직책의
          차기 집행부원을 선출하여야 한다.
          <br /> 단, 당사자가 동항 제1호의 추천을 할 수 없는 경우 회장이
          추천한다.
        </p>
        <h2>제6장 모임</h2>
        <h3>제29조&#40;정기 모임&#41;</h3>
        <p>
          ① 본회는 본회 목적에 맞는 모임을 격달에 한 번 이상 실시해야 하며, 이를
          정기 모임이라 한다.
          <br />
          ② 정기 모임에서는 마술 중심의 내용을 다루는 것을 원칙으로 한다.
          <br />
          ③ 정기 모임의 내용, 장소 등은 집행부가 결정하며, 모임 시작 일시 최소
          1일 이전에 공식 게시 매체를 통해 소집을 공지하여야 한다.
          <br />④ 천재지변 등 불가피한 상황 발생 시, 집행부의 재량에 따라 1회
          취소할 수 있으며 공식 매체를 통해 공지하여야한다.
        </p>
        <h3>제30조&#40;비정기 모임&#41;</h3>
        <p>
          ① 정기 모임이 아닌 모든 모임을 비정기 모임이라 한다.
          <br />
          ② 비정기 모임의 형식, 내용, 장소 등은 집행부가 자유롭게 결정하며,
          버스킹 등의 형태일 수 있다.
          <br />③ 비정기 모임은 집행부가 수시로 소집할 수 있다.
        </p>
        <h3>제31조&#40;모임의 시작과 종료&#41;</h3>
        <p>
          ① 모임의 시작과 종료는 집행부의 공지를 따른다.
          <br />
          ② 모임의 종료 이후 발생한 추가 모임이나 일련의 사건은 본회와 무관한
          개인적 모임으로 간주하며, 본 회칙을 적용하지 않는다.
          <br />
        </p>
        <h3>제32조&#40;모임의 참가비와 예약&#41;</h3>
        <p>
          ① 참가비가 없는 모임을 무료 모임, 참가비가 있는 모임을 유료 모임이라
          하며, 참가비의 유무와 액수는 집행부가 정한다.
          <br />② 모임의 참여 의사를 집행부가 공지한 방법을 통해 밝히는 것을
          예약이라 하며, 유료 모임의 경우 참가비를 지불해야 예약이 완료된 것으로
          본다.
        </p>
        <h3>제33조&#40;예약 후 불참의 불이익&#41;</h3>
        <p>
          ① 무료 모임을 예약한 후 집행부에 특별한 사유를 알리지 않고 불참한
          회원은 가장 가까운 다음 정기 모임에 예약할 수 없다.
          <br />
          ② 유료 모임을 예약한 후 집행부에 특별한 사유를 알리지 않고 불참한
          회원에게는 참가비를 환불하지 않는다.
          <br />
          ③ 유료 모임을 예약한 후 모임 시작 전에 예약을 취소하는 경우 집행부는
          다음 각 호에 따라 참가비를 해당 회원에게 환불해야 한다.
          <br />
          <br />
          1. 모임 10일 전까지 취소한 경우 전액 환불한다.
          <br />
          2. 모임 7일 전까지 취소한 경우 참가비의 10%를 공제한 후 환불한다.
          <br />
          3. 모임 3일 전까지 취소한 경우 참가비의 50%를 공제한 후 환불한다.
          <br />
          4. 모임 2일 전에 취소한 경우 참가비를 환불하지 않는다.
          <br />
          5. 상기 각 호에도 불구하고, 예약 당일 취소한 경우는 참가비를 전액
          환불한다.
          <br />
          <br />④ 각 모임에 추가적으로 적용되는 세부 불이익에 관한 사항은 해당
          모임의 공지를 우선적으로 따른다.
        </p>
        <h3>제34조&#40;민원&#41;</h3>
        <p>
          ① 민원의 제기는 음성 녹음, 문자 메시지 등과 같은 증거가 존재할 때
          유효하다.
          <br />
          ② 특정 집행부원에 대한 민원은 다른 집행부원에게 제기함을 원칙으로
          한다.
          <br />③ 모임의 기획 및 소집을 요청하는 민원의 경우 집행부의 허가를
          통해 본회 정회원이 모임 기획 및 소집의 주체가 될 수 있다.
        </p>
        <h2>제7장 재정</h2>
        <h3>제35조&#40;재정 관리 주체&#41;</h3>
        <p>
          ① 재정 관리의 책임자는 회장으로 한다.
          <br />② 재정 관리의 총 주체는 총무로 한다.
        </p>
        <h3>제36조&#40;지출 증빙&#41;</h3>
        <p>
          ① 집행부원은 공금의 지출을 다음 각 호 중 하나 이상의 방법을 통해
          증빙하며, 총무가 이를 수합, 기록함을 원칙으로 한다.
          <br />
          <br />
          1. 거래일시, 승인번호, 거래금액이 표기된 카드매출전표
          <br />
          2. 모든 품목명이 표기된 영수증 혹은 간이영수증
          <br />
          3. 거래일시, 승인번호, 거래금액이 표기된 현금영수증. 특히 국세청
          사이트에서 확인이 가능해야 한다.
          <br />
          4. 보낸 계좌번호, 받는 계좌번호와 예금주가 표기된 계좌이체내역서
          <br />
          5. 기타 법적으로 효력이 있는 계약서
        </p>
        <h3>제37조&#40;공금 계좌&#41;</h3>
        <p>
          ① 공금을 보관할 은행 계좌는 본회 공식 명의, 회장 명의, 총무 명의의
          계좌만을 이용한다.
          <br />② 공금을 보관할 은행 계좌는 제1금융권에 개설된 것만을 이용한다.
          제8장 공식 매체
        </p>
        <h3>제38조&#40;목적&#41;</h3>
        <p>
          ① 본회의 공식 매체는 본회의 대외 홍보 및 대내 공지를 목적으로 한다.
        </p>
        <h3>제39조&#40;구성&#41;</h3>
        <p>
          ① 본회의 공식 매체는 다음 각 호로 구성된다. 1. 인스타그램
          @magic_club_official 계정
          <br />
          2. 인터넷 웹사이트 magicclub.kr
        </p>
        <h3>제40조&#40;접근 권한&#41;</h3>
        <p>
          ① 인터넷 웹사이트를 제외한 공식 매체와, 인터넷 웹사이트 공지 페이지의
          작성, 수정, 삭제 권한은 집행부에게 있다.
          <br />② 제1항에도 불구하고 공식 매체 이용을 원하는 회원이 집행부의
          허가를 득한 경우 모든 공식 매체를 사용할 수 있다.
        </p>
        <h3>제41조&#40;공식 매체의 운영자&#41;</h3>
        <p>
          ① 공식 매체의 최고 운영자는 회장이 맡는 것을 원칙으로 한다.
          <br />② 회장은 재량에 따라 공식 매체의 운영자를 임명할 수 있다.
        </p>
        <h3>제42조&#40;회원 등급이 구분된 공식 매체&#41;</h3>
        <p>
          ① 회원 등급이 구분된 공식 매체를 운영하는 경우, 최고 운영자는 본회
          회원들의 분류 및 직책에 따라 공식 매체에서의 회원 등급을 수시로
          조정해야 한다.
          <br />② 회원 등급은 회장, 집행부원, 정회원, 준회원으로 구분하는 것을
          원칙으로 한다.
        </p>
        <h2>제9장 비상대책위원회</h2>
        <h3>제43조&#40;지위 및 목적&#41;</h3>
        <p>
          ① 회장의 궐위, 사고 및 탄핵 등으로 인해 본회의 정상적 운영이 불가능한
          경우, 본회의 정상화를 위하여 비상대책위원회를 구성할 수 있다.
          <br />② 비상대책위원회는 본회의 실무적 최고의결기구 및 최고집행기구가
          된다.
        </p>
        <h3>제44조&#40;구성&#41;</h3>
        <p>
          ① 직전 집행부는 내부 호선을 통해 본회 정회원을 비상대책위원으로 인준할
          수 있다. <br />
          모든 비상대책위원의 인준이 종료된 직후 집행부는 해산하고
          비상대책위원회가 구성된다.
          <br />② 비상대책위원장은 비상대책위원회 내부 호선으로 선출한다.
        </p>
        <h3>제45조&#40;임기 및 역할&#41;</h3>
        <p>
          ① 비상대책위원회는 다음 각 호의 조건을 모두 만족한 경우 즉시 해산한다.
          <br />
          <br />
          1. 본회의 운영이 객관적인 근거에 의해 정상화되었음을 비상대책위원회
          내부에서 판단한 경우
          <br />
          2. 비상대책 위원회의 간접 선거를 통해 회장이 선출된 경우
          <br />
          <br />
          ② 비상대책위원회는 다음 각 호에 명시된 역할을 수행한다.
          <br />
          <br />
          1. 본회 집행부 총회에서 가능한 심의, 의결과 집행부에서 가능한 업무
          집행
          <br />
          2. 본회의 대외적인 활동에서 대표자 직의 대리
          <br />
          3. 본회의 대내적인 활동에서 회원 통솔 및 최종 책임
          <br />
          4. 본회 내외에서 통상적이지 않은 사건 발생 시, 객관적인 사실의 공포,
          사건해결을 위한 조속한 노력, 대책 강구 및 실행
        </p>
        <h2>제10장 징계</h2>
        <h3>제46조&#40;발의&#41;</h3>
        <p>
          ① 다음 각 호 중 하나 이상에 해당되는 경우 징계안이 집행부 총회로
          발의된다.
          <br />
          <br />
          1. 회장의 발의
          <br />
          2. 집행부 1/3 이상의 연서에 의한 발의
          <br />
          3. 정회원 1/3 이상의 연서에 의한 발의
          <br />
          4. 제47조의 징계 요건을 만족하는 경우
        </p>
        <h3>제47조&#40;징계 요건&#41;</h3>
        <p>
          ① 타 회원에게 직·간접적인 피해를 입힌 경우
          <br />
          ② 본회의 목적과 인륜, 사회적으로 통용되는 도덕 가치를 위배하는 다음 각
          호에 해당된 행동을 한 경우
          <br />
          <br />
          1. 성폭력
          <br />
          2. 폭력
          <br />
          3. 차별 등 인권을 침해하는 행위
          <br />
          4. 기타 반사회적인 행위
        </p>
        <h3>제48조&#40;절차&#41;</h3>
        <p>
          ① 제48조에 의해 발의된 징계안에 대한 집행부 총회의 의결로 징계 내용을
          결정한다.
          <br />
          ② 징계 대상자에게 징계 내용을 통보한 후, 3일간의 이의 신청 및 소명의
          기회를 부여한다.
          <br />
          ③ 징계 대상자의 이의 신청 및 소명이 없을 경우, 즉시 공식 게시 매체를
          통해 징계 내용을 공고한다. <br />
          이때, 집행부의 판단에 따라 징계 대상자의 신원은 공개하지 않을 수 있다.
          <br />
          ④ 징계 대상자의 이의 신청 및 소명이 있을 경우, 집행부 총회에 징계안을
          다시 상정한다.
          <br /> 재의결된 징계 내용에 대한 이의 신청 및 소명의 기회는 부여되지
          않고, 재의결 3일 이후 공식 게시 매체를 통해 징계 내용을 공고한다.
        </p>
        <h3>제49조&#40;징계 내용&#41;</h3>
        <p>
          ① 일반적인 징계 내용은 다음 각 호 중 하나 이상으로 한다.
          <br />
          <br />
          1. 징계 없음
          <br />
          2. 경고
          <br />
          3. 사과문 게시
          <br />
          4. 본회 행사 참여 일시 제한
          <br />
          5. 회원 자격 일시 박탈
          <br />
          6. 영구 제명
          <br />
          <br />② 징계 대상자가 본회에 금전적 배상을 할 필요성이 명확한 경우,
          제1항에 더하여 금전적 배상을 요구할 수 있다.
        </p>
        <h3>제50조&#40;징계의 효력&#41;</h3>
        <p>
          ① 본회 공식 게시 매체를 통해 징계 내용이 공고된 시점부터 징계의 효력이
          발생한다.
          <br />② 기간이 정해진 징계인 제48조제1항제4호와 제5호의 경우, 효력이
          발생한 초일도 산입하여 징계 기간을 계산한다.
        </p>
        <h3>제51조&#40;회원 자격을 박탈하는 징계의 부대의결&#41;</h3>
        <p>
          ① 회원 자격을 박탈하는 징계인 제48조제1항제5호와 제6호의 경우, <br />
          집행부는 본회 공식 매체 및 내부 소통 매체에서 징계 당사자의 등급을
          조정하거나 퇴장 명령을 할 수 있다.
        </p>
        <h2>제11장 회칙 개정</h2>
        <h3> 제52조&#40;절차&#41;</h3>
        <p>
          ① 본회의 회칙 개정안은 집행부원 전원의 연서를 통해 집행부 총회로
          발의되어, 집행부 총회의 의결로 개정한다.
          <br />② 회칙 개정이 이루어진 집행부 총회의 결과를 공고할 때는
          신구문대조표를 첨부해야 한다. 단, 전부 개정의 경우 그러지 아니한다.
        </p>
        <h3>제53조&#40;회칙 개정의 효력 발생 및 취소&#41;</h3>
        <p>
          ① 개정된 회칙은 공식 매체에 게시된 순간부터 그 효력을 갖는다.
          <br />② 회칙 개정의 취소 혹은 개정된 조항의 재개정은 개정 일시의
          익년도부터 가능하다.
        </p>
      </main>
    </div>
  );
};

export default Rule;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import style from "./Archive.module.scss";

const API = process.env.REACT_APP_API;

interface EventArchive {
  event_id: number;
  event_title: string;
  event_url: string;
  evented_at: string;
  finished_at: string;
  names: string;
  phone_number: string;
  place: string;
  show_number: number;
}

const Archive = () => {
  const [events, setEvents] = useState<EventArchive[]>([]);

  const getArchive = async () => {
    await axios
      .get(`${API}/api/user-event/me`, {
        withCredentials: true,
      })
      .then((data) => {
        setEvents(data.data.body);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getArchive();
  }, []);

  const isEventExpired = (dateString: string) => {
    const eventDate = new Date(dateString);
    const today = new Date();
    return eventDate < today;
  };

  const eventCancle = (
    cancledEventId: number,
    cancledShowNumber: number,
    cancledEventTitle: string
  ) => {
    const cancleResult = window.confirm(
      `${cancledEventTitle}를 취소하시겠습니까?`
    );
    if (cancleResult) {
      const cancle = axios
        .post(
          `${API}/api/user-event/delete`,
          {
            event_id: cancledEventId,
            show_number: cancledShowNumber,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          getArchive();
          alert(
            "해당 이벤트가 취소되었습니다. 환불 문의는 인스타그램 @magic_club.kr로 부탁드려요."
          );
        })
        .catch((err) => {
          alert(
            "이벤트 취소에 실패했습니다. 지속적인 실패 시 인스타그램 @magic_club.kr로 문의 주세요."
          );
        });
    } else {
      return;
    }
  };

  return (
    <div className={style.archive}>
      <div className={style.header}>
        <h1>이벤트 예약 내역</h1>
        <hr />
      </div>
      <div className={style.eventGrid}>
        {events.length > 0 ? (
          events.map((event) => (
            <div className={style.eventWrapper}>
              <div key={event.event_id} className={style.eventItem}>
                {isEventExpired(event.finished_at) && (
                  <div className={style.eventExpired}>마감</div>
                )}
                <img
                  src={`./img/${event.event_url}`}
                  alt={event.event_title}
                  className={style.eventImage}
                />
                <div className={style.eventDetails}>
                  <h2 className={style.eventTitle}>{event.event_title}</h2>
                  <div className={style.eventMeta}>
                    <p>
                      <span className={style.detail_title}>장소</span>
                      <span className={style.detail_contents}>
                        {event.place}
                      </span>
                    </p>
                    <p>
                      <span className={style.detail_title}>시간</span>
                      <span className={style.detail_contents}>
                        {event.evented_at.slice(2, 4)}.
                        {event.evented_at.slice(5, 7)}.
                        {event.evented_at.slice(8, 10)}
                        <br />
                        {event.evented_at.slice(-8, -3)} ~
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className={style.btn_container}>
                <Link
                  to={`/events/${event.event_id}`}
                  className={style.eventLink}
                >
                  이벤트 상세 보기
                </Link>
                <button
                  className={style.cancleBtn}
                  onClick={() =>
                    eventCancle(
                      event.event_id,
                      event.show_number,
                      event.event_title
                    )
                  }
                >
                  예약 취소하기
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>예약된 정보가 없습니다.</p>
        )}
      </div>
    </div>
  );
};

export default Archive;

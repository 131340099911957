import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "./slick_banner.css";
import "./slick-theme_banner.css";
import style from "./BannerSlider.module.scss"

const calcVw = (px: number, base: number) => {
  return `${(px / base) * 100}vw`;
};
const breakpointMobile = "720px";
const breakpointContent = "1600px";
const headerHeight = "100px";

const Banner = styled.div`
  @media (max-width: 720px) {
    height: 500px;
  }
  width: 100%;
  max-width: ${breakpointContent};
  margin: 0 auto;
  height: 600px;
  border-bottom: #888 1px solid;
  position: relative;
`;

const Titles = styled.div`
  @media (max-width: 720px) {
    bottom: ${calcVw(100, 720)};
    left: ${calcVw(20, 720)};
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 90px;
  position: absolute;
  bottom: ${calcVw(80, 1920)};
  left: ${calcVw(100, 1920)};
`;

const Title = styled.p`
  font-family: "Pretendard Variable" sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 12px;
  text-shadow: -1px 0 #ffffff, 0 1px #ffffff, 1px 0 #ffffff, 0 -1px #ffffff;
  color: rgb(112, 112, 112);
`;
const SubTitle = styled.p`
  font-family: "Pretendard Variable", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
  text-shadow: -1px 0 rgb(112, 112, 112), 0 1px rgb(112, 112, 112),
    1px 0 rgb(112, 112, 112), 0 -1px rgb(112, 112, 112);
`;

const ImgFrame = styled.div`
  @media (max-width: 720px) {
    height: 500px;
  }
  flex-grow: 0;
  flex-shrink: 0;
  height: 600px;
  width: 100%;
  max-width: ${breakpointContent};
  position: relative;
  overflow: hidden;
`;

const Img = styled.img`
  @media (max-width: 720px) {
    min-height: 500px;
  }
  min-height: 600px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

const Arrow = styled.div`
  display: none !important;
`;

const ArrowIcon = styled.img`
  width: 65%;
  height: 65%;
`;

function NextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={`${className} ${style.rArrow}`} onClick={onClick}>
      <ArrowIcon src="../img/icons/rarrow.png" />
    </div>
  );
}

function PrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={`${className} ${style.lArrow}`} onClick={onClick}>
      <ArrowIcon src="../img/icons/larrow.png" />
    </div>
  );
}

export default function BannerSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow></NextArrow>,
    prevArrow: <PrevArrow></PrevArrow>,
  };

  return (
    <Banner>
      <Slider {...settings}>
        {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
          <ImgFrame key={i}>
            <Img src={`./img/mainBanner/${i}.jpg`} />
          </ImgFrame>
        ))}
      </Slider>
      <Titles>
        <Title>Magic Club Korea</Title>
        <SubTitle>마술 놀이터</SubTitle>
      </Titles>
    </Banner>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./InfoEdit.module.scss";

const API = process.env.REACT_APP_API;

interface InfoResponse {
  body: {
    email: string;
    name: string;
    phone_number: string;
  };
  result: string;
}

const InfoEdit = () => {
  const [name, setName] = useState("");
  const [exPW, setExPW] = useState("");
  const [newPW, setNewPW] = useState("");
  const [newPWVerify, setNewPWVerify] = useState("");
  const [exPhoneNumber, setExPhoneNumber] = useState("");
  const [phoneNumberFirstDigit, setPhoneNumberFirstDigit] = useState("010");
  const [phoneNumberSecondDigit, setPhoneNumberSecondDigit] = useState("");
  const [phoneNumberLastDigit, setPhoneNumberLastDigit] = useState("");
  //비밀번호 확인
  const [showExPW, setShowExPW] = useState(false);
  const [showNewPW, setShowNewPW] = useState(false);
  const [showNewPWVerify, setShowNewPWVerify] = useState(false);
  const navigate = useNavigate();

  const getInfo = () => {
    axios
      .get<InfoResponse>(`${API}/api/user/me`, {
        withCredentials: true,
      })
      .then((data) => {
        setName(data.data.body.name);
        setExPhoneNumber(data.data.body.phone_number);
        console.log(data);
      })
      .catch((err) => {
        alert("회원 정보를 불러올 수 없습니다.");
      });
  };
  useEffect(() => {
    getInfo();
  }, []);

  const MyPageSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // 이전 비밀번호 확인
    if (exPW === "") {
      alert("이전 비밀번호를 채워주세요.");
      return;
    }
    //이전 비밀번호 외 모두 적혀있지 않음
    if (
      newPW === "" &&
      newPWVerify === "" &&
      phoneNumberSecondDigit === "" &&
      phoneNumberLastDigit === ""
    ) {
      alert("...네?");
      return;
    }
    //비밀번호 확인
    if (newPW !== newPWVerify) {
      alert("새 비밀번호가 비밀번호 확인과 다릅니다.");
      setNewPWVerify("");
      return;
    }

    // 전화번호 확인
    let finalPhoneNumber = exPhoneNumber;

    if (phoneNumberSecondDigit === "" && phoneNumberLastDigit === "") {
      finalPhoneNumber = exPhoneNumber;
    } else if (phoneNumberSecondDigit === "" || phoneNumberLastDigit === "") {
      alert("전화번호를 확인해주세요.");
      return;
    } else {
      finalPhoneNumber = `${phoneNumberFirstDigit}-${phoneNumberSecondDigit}-${phoneNumberLastDigit}`;
    }

    try {
      const response = axios
        .put(
          `${API}/api/user`,
          {
            prev_password: exPW,
            password: newPW === "" ? exPW : newPW,
            phone_number: finalPhoneNumber,
          },
          { withCredentials: true }
        )
        .then(async () => {
          alert("회원정보가 수정되었습니다.");
          getInfo();
          window.location.replace("/my-page");
        })
        .catch((err) => {
          alert(err.response.data.result.resultDescription);
        });
    } catch (err) { }
  };

  return (
    <div className={style.personalInfo}>
      <h1 className={style.title}>나의 정보 수정하기</h1>
      <hr />

      <form className={style.form} onSubmit={MyPageSubmit}>
        <div className={style.label}>
          <span className={style.title__label}>회원 이름:</span>
          <span> {name}</span>
        </div>
        <hr />
        <div className={style.label}>
          <span className={style.title__label}>회원 등급:</span>
          <span>일반 회원</span>
        </div>
        <hr />
        <label className={`${style.label} ${style.tangible}`}>
          <span className={style.title__label}>이전 비밀번호</span>
          <div className={style.input_container}>
            <input
              className={style.input}
              type={showExPW ? "text" : "password"}
              value={exPW}
              onChange={(e) => setExPW(e.target.value)}
              placeholder="기존 비밀번호를 입력해주세요."
              required
            />
            <button
              type="button"
              onClick={() => setShowExPW(!showExPW)}
              className={style.visibility__btn}
            >
              {showExPW ? (
                <img
                  src="./img/icons/invisible.png"
                  alt="비밀번호 숨기기"
                  className={style.visibility__icon}
                />
              ) : (
                <img
                  src="./img/icons/visible.png"
                  alt="비밀번호 보기"
                  className={style.visibility__icon}
                />
              )}
            </button>
          </div>
        </label>
        <label className={`${style.label} ${style.tangible}`}>
          <span className={style.title__label}>비밀번호 변경</span>
          <div className={style.input_container}>
            <input
              className={style.input}
              type={showNewPW ? "text" : "password"}
              value={newPW}
              onChange={(e) => setNewPW(e.target.value)}
              minLength={8}
              maxLength={100}
              pattern="^[a-z\d!@*&-_]{8,16}$"
            />
            <button
              type="button"
              onClick={() => setShowNewPW(!showNewPW)}
              className={style.visibility__btn}
            >
              {showNewPW ? (
                <img
                  src="./img/icons/invisible.png"
                  alt="비밀번호 숨기기"
                  className={style.visibility__icon}
                />
              ) : (
                <img
                  src="./img/icons/visible.png"
                  alt="비밀번호 보기"
                  className={style.visibility__icon}
                />
              )}
            </button>
            <p className={style.passwordCriteria}>
              특수문자 포함, 영어 소문자 8~16자
            </p>
          </div>
        </label>
        <label className={`${style.label} ${style.tangible}`}>
          <span className={style.title__label}>비밀번호 변경 확인</span>
          <div className={style.input_container}>
            <input
              className={style.input}
              type={showNewPWVerify ? "text" : "password"}
              value={newPWVerify}
              onChange={(e) => setNewPWVerify(e.target.value)}
              minLength={8}
              maxLength={100}
              pattern="^[a-z\d!@*&-_]{8,16}$"
              placeholder="비밀번호를 한 번 더 입력해주세요."
            />
            <button
              type="button"
              onClick={() => setShowNewPWVerify(!showNewPWVerify)}
              className={style.visibility__btn}
            >
              {showNewPWVerify ? (
                <img
                  src="./img/icons/invisible.png"
                  alt="비밀번호 숨기기"
                  className={style.visibility__icon}
                />
              ) : (
                <img
                  src="./img/icons/visible.png"
                  alt="비밀번호 보기"
                  className={style.visibility__icon}
                />
              )}
            </button>
          </div>
        </label>
        <hr />
        <label className={`${style.label} ${style.tangible}`}>
          <span className={style.title__label}>연락처 변경</span>
          <div className={style.phone_number}>
            <input
              className={style.input_phone}
              type="text"
              value={phoneNumberFirstDigit}
              onChange={(e) => setPhoneNumberFirstDigit(e.target.value)}
              maxLength={3}
              placeholder={exPhoneNumber != null ? exPhoneNumber.slice(0, 3) : ""}
              required
            />
            -
            <input
              className={style.input_phone}
              type="text"
              value={phoneNumberSecondDigit}
              onChange={(e) => setPhoneNumberSecondDigit(e.target.value)}
              maxLength={4}
              minLength={4}
              placeholder={exPhoneNumber != null ? exPhoneNumber.slice(4, 8) : ""}
              pattern="^\d*"
            />
            -
            <input
              className={style.input_phone}
              type="text"
              value={phoneNumberLastDigit}
              onChange={(e) => setPhoneNumberLastDigit(e.target.value)}
              maxLength={4}
              minLength={4}
              placeholder={exPhoneNumber != null ? exPhoneNumber.slice(9, 13) : ""}
              pattern="^\d*"
            />
          </div>
        </label>
        <button className={style.submit_btn} type={"submit"}>
          수정하기
        </button>
      </form>
    </div>
  );
};

export default InfoEdit;

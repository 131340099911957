import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "./OwnerYoutube.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/authContext/AuthContext";

const API = process.env.REACT_APP_API;

interface InfoResponse {
  body: {
    email: string;
    name: string;
    user_state: string;
  };
  result: string;
}

interface YoutubeUpdate {
  body: string;
}

interface Video {
  id: {
    kind: string;
    videoId: string;
  };
  snippet: {
    publishedAt: string;
    title: string;
  };
}


const OwnerYoutube = () => {
  const navigate = useNavigate();
  const [isLend, setIsLend] = useState(false);
  const [eventList, setEventList] = useState<Event[]>([]);
  const { id, num } = useParams<{ id: string, num: string }>();

  const YOUTUBE_KEY = process.env.REACT_APP_YOUTUBE_API;

  useEffect(() => {
    const getInfo = axios.get<InfoResponse>(`${API}/api/user/me`, {
      withCredentials: true,
    });
    getInfo
      .then((data) => {
        console.log(data);
        if (data.status == 200) {
          if(data.data.body.user_state != "OWNER") navigate("/");
          setIsLend(true);
        }
      })
      .catch((e) => {
        navigate("/");
      });
  }, []);

  async function updateYoutube(){
    const searchResponse = await axios.get(
      'https://www.googleapis.com/youtube/v3/search', {
        params: {
          part: 'snippet',
          channelId: "UCfZgnRpYmdlUF3IHruwYz8A",
          maxResults: 20,
          order: 'date',
          type: 'video',
          key: YOUTUBE_KEY,
        },
      }
    )

    const videoIds = searchResponse.data.items.map((item: any) => item.id.videoId);

    const videoResponse = await axios.get(
      'https://www.googleapis.com/youtube/v3/videos', {
        params: {
          part: 'contentDetails',
          id: videoIds.join(','),
          key: YOUTUBE_KEY,
        },
      }
    );

    var cnt = 0;

    const filteredVideos = searchResponse.data.items.filter((item: any) => {
      if(cnt >= 4) return false;
      const videoId = item.id.videoId;
      const contentDetails = videoResponse.data.items.find((video: any) => video.id === videoId);

      if (!contentDetails) return false;
      console.log(contentDetails)

      // 동영상 길이 확인 (ISO 8601 형식으로 'PT1M' 이하이면 Shorts로 간주)
      const duration = contentDetails.contentDetails.duration;
      if (duration.includes('PT1M') || duration.length<=5 && duration.includes('S')) {
        return false; // Shorts로 제외
      }

      cnt++;
      console.log(item)
      
      return true; // Shorts가 아닌 동영상만 반환
    });

    const longVideoIds = filteredVideos.map((video: any) => {
      return video.id.videoId
    })
    const titles = filteredVideos.map((video: any) => {
      return video.snippet.title
    })
    const publishedAt = filteredVideos.map((video: any) => {
      return video.snippet.publishedAt
    })
    console.log(longVideoIds)



    const getInfo = axios.post<YoutubeUpdate>(`${API}/api/simple-youtube`, {
      youtube_id: longVideoIds,
      title: titles,
      published_at: publishedAt
    },{
      withCredentials: true,
    }).then(result => {
      alert(result.data.body);
    });
  }

  return (
    <div className={style.login}>
      {
        !isLend ? <div></div> : (
          <div className="no">
            <button onClick={updateYoutube}>갱신</button>
          </div>
        )
      }
    </div>
  );
};

export default OwnerYoutube;

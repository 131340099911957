import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../authContext/AuthContext";
import style from "./Header.module.scss";

const Header = () => {
  const {isLogin, logout} = useAuth();
  const [view, setView] = useState(false);
  const navigate = useNavigate();

  async function logout_(){
    const API = process.env.REACT_APP_API;
    const response = await axios.get(`${API}/api/user/logout`,{
      withCredentials: true,
    });
    alert("로그아웃 되었습니다");
    logout();
    navigate("/");
  }

  return (
    <div className={style.header}>
      <div className={style.content}>
        <div className={`${style.menu} ${style.mobile} ${view ? style.show : style.noShow}`}>
          <div className={(view ? `${style.dropdown} ${style.show}` : `${style.dropdown} ${style.noShow}`)}>
            <div className={style.dropdownCancelButton}>
              <img src="/img/etc/x.png" alt="" className={style.img} onClick={()=>{setView(false)}}/>
            </div>
            <Link to={"/events"} className={style.gnb} onClick={()=>{setView(false)}}>
              Events
            </Link>
            {
              isLogin ? (
                <Link to="/my-page" className={style.gnb} onClick={()=>{setView(false)}}>
                  My Page
                </Link>
              ) : (
                <Link to={`/login`} className={style.gnb} onClick={()=>{setView(false)}}>
                  Login
                </Link>
              )
            }
            {
              isLogin ? (
                <div onClick={logout_} className={style.gnb}>Logout</div>
              ) : (
                <Link to="/register" className={style.gnb} onClick={()=>{setView(false)}}>
                  Join Us
                </Link>
              )
            }
          </div>
        </div>
        <div className={`${style.menu} ${style.pc}`}>
          <Link to={"/events"} className={`${style.gnb} ${style.pc}`}>
            Events 
            <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.4706 6.06359L14.4704 6.06378L8.84562 11.4635C8.82354 11.4847 8.78939 11.5 8.74971 11.5C8.71004 11.5 8.67589 11.4847 8.6538 11.4635C8.63241 11.4429 8.62439 11.4196 8.62439 11.3997C8.62439 11.3799 8.63241 11.3565 8.65377 11.3359C8.65378 11.3359 8.65379 11.3359 8.6538 11.3359L13.2122 6.96069L14.109 6.09997H12.866H0.62498C0.585393 6.09997 0.551331 6.08468 0.529314 6.06354C0.507991 6.04307 0.5 6.01977 0.5 6C0.5 5.98023 0.507991 5.95693 0.529314 5.93646C0.551331 5.91532 0.585393 5.90003 0.62498 5.90003H12.866H14.109L13.2122 5.03931L8.6538 0.664079C8.63241 0.643545 8.62439 0.620156 8.62439 0.6003C8.62439 0.580444 8.63241 0.557055 8.6538 0.536521C8.67589 0.515323 8.71004 0.5 8.74971 0.5C8.78939 0.5 8.82354 0.515323 8.84562 0.536521L14.4704 5.93622L14.4706 5.93641C14.4814 5.94669 14.4887 5.95777 14.4933 5.96846C14.4979 5.97911 14.5 5.98976 14.5 6C14.5 6.01024 14.4979 6.02089 14.4933 6.03153C14.4887 6.04223 14.4814 6.05331 14.4706 6.06359Z" fill="black" stroke="black"/>
            </svg>
          </Link>
        </div>
        <Link to={"/"} className={`${style.main} ${style.pc}`}>
          <img src="/img/MainLogoLong.png" alt="" className={style.img}/>
        </Link>
        <div className={`${style.userInfo} ${style.pc}`}>
          {
            isLogin ? (
              <>
                <div onClick={logout_} className={style.login}>Logout</div>
                <Link to={"/my-page"} className={style.joinUs}>
                My Page
                </Link>
              </>
            ) : (
              <>
                <Link to={"/login"} className={style.login}>
                  Login
                </Link>
                <Link to={"/register"} className={style.joinUs}>
                  Join Us
                </Link>
              </>
            )
          }
        </div>
        <div className={style.mobile}>
          <Link to={"/"} className={style.mobileMain}>
            <img src="/img/MainLogoLong.png" alt="" className={style.img}/>
          </Link>
          <div className={style.dropdownButton} onClick={()=>{setView(!view)}}>
            <img src="/img/etc/menu_btn.png" alt="" className={style.img}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React, { useState } from "react";
import axios from "axios";
import style from "./Password.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../components/authContext/AuthContext";

const API = process.env.REACT_APP_API;

interface LoginResponse {
  body: {
    email: string;
    name: string;
  };
  result: string;
}

interface UserInfoResponse {
  name: string;
  email: string;
}

const Password = () => {
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();
  const PasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = axios
        .post<LoginResponse>(
          `${API}/open-api/send-mail/password`,
          {
            email: email
          },
          { withCredentials: true }
        )
        .then(async (data) => {
          alert("임시 비밀번호를 메일로 전송했습니다. 로그인 후 비밀번호를 수정해 주세요");
        })
        .catch((e) => {
          alert("이메일과 비밀번호를 다시 확인해 주세요");
        });
    } catch (err) {}
  };

  return (
    <div className={style.login}>
      <h1 className={style.title}>비밀번호 변경</h1>
      <form className={style.form} onSubmit={PasswordSubmit}>
        <label className={style.label}>
          <span className={style.label__span}>이메일</span>
          <input
            className={style.input}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="이메일을 입력해주세요"
            required
          />
        </label>
        <button className={style.login_btn} type="submit">
          메일 전송
        </button>
      </form>
    </div>
  );
};

export default Password;

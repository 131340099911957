import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/authContext/AuthContext";
import style from "./EventDetail.module.scss";

interface EventApplyResponse {
    data: {
        token: string;
    };
}
interface Event {
    title: string;
    place: string;
    evented_at: string;
    finished_at: string;
    money: number;
    context: string | null;
    img_url: string;
    all_amount: number;
    early_bird_amount: number;
    early_bird_at: string | null;
    rounds: { id: number; time: string; }[];
    registered_users: number;
    close_min: number;
    performers: string;
}

const EventDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { isLogin } = useAuth();
    const [event, setEvent] = useState<Event | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const API = process.env.REACT_APP_API;
                const response = await axios.get<{ result: any, body: any[] }>(`${API}/open-api/event/${id}`);
                const event = {
                    ...response.data.body[0],
                    rounds: response.data.body.map((round) => ({
                        id: round.show_id,
                        time: new Date(round.evented_at).toLocaleString() + ' ~ ' + new Date(round.finished_at).toLocaleString(),
                    })),
                };
                console.log(event)
                setEvent(event);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch event');
                setLoading(false);
            }
        };

        fetchEvent();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 719);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [id]);

    const goToLogin = () => {
        navigate('/login');
    };

    const goToRegister = () => {
        if (isLogin) {
            navigate(`/event-register/${id}`);
        } else {
            alert("로그인이 필요합니다.");
            goToLogin();
        }
    };

    const redirectToCurrentPage = () => {
        navigate(`/events/${id}`);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!event) {
        return <div>Event not found</div>;
    }

    const formatMoney = (amount: number) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const eventDate = new Date(event.evented_at);
    const isPastEvent = eventDate < new Date();

    return (
        <>
            <div className={style.eventDetail}>
                <div className={style.eventDetailheader}>
                    <h1>매직클럽 이벤트</h1>
                    <p>매직클럽에서 진행 중이거나 진행이 완료된 이벤트입니다.</p>
                </div>
                <div className={style.divider}></div>

                {isMobile ? (
                    <div className={style.mobileEventContent}>
                        <div className={style.eventImageContainer}>
                            <img src={`/img/${event.img_url}`} alt={event.title} className={style.eventImage} />
                        </div>
                        <div className={style.eventInfoContainer}>
                            <h2 className={style.eventTitle}>{event.title}</h2>
                            <div className={style.buttonContainer}>
                                <button
                                    type="submit"
                                    className={`${style.eventButton} ${isPastEvent ? style.disabledButton : ''}`}
                                    onClick={isPastEvent ? redirectToCurrentPage : goToRegister}
                                    disabled={isPastEvent}
                                >
                                    {isPastEvent ? "종료" : "예약하기"}
                                </button>
                            </div>
                        </div>
                        <div className={style.eventDetails}>
                            <p><strong>장소</strong> <span>{event.place}</span></p>
                            <p><strong>날짜</strong> <span>{eventDate.toLocaleString()}</span></p>
                            <p><strong>인원</strong> <span>{event.close_min}명 이내</span></p>
                            <p><strong>가격</strong> <span>{formatMoney(event.money)} 원</span></p>
                            <p><strong>출연자</strong> <span>{event.performers}</span></p>
                        </div>
                    </div>
                ) : (
                    <div className={style.eventContent}>
                        <div className={style.eventImageContainer}>
                            <img src={`/img/${event.img_url}`} alt={event.title} className={style.eventImage} />
                        </div>
                        <div className={style.eventInfoContainer}>
                            <h2 className={style.eventTitle}>{event.title}</h2>
                            <p><strong>장소</strong> <span>{event.place}</span></p>
                            <p><strong>날짜</strong> <span>{eventDate.toLocaleString()}</span></p>
                            <p><strong>인원</strong> <span>{event.close_min}명 이내</span></p>
                            <p><strong>가격</strong> <span>{formatMoney(event.money)} 원</span></p>
                            <p><strong>출연자</strong> <span>{event.performers}</span></p>
                            <div className={style.buttonContainer}>
                                <button
                                    type="submit"
                                    className={`${style.eventButton} ${isPastEvent ? style.disabledButton : ''}`}
                                    onClick={isPastEvent ? redirectToCurrentPage : goToRegister}
                                    disabled={isPastEvent || !isLogin}
                                >
                                    {isPastEvent ? "이벤트 종료" : "이벤트 예약하기"}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div className={style.divider}></div>
                <div
                    className={style.eventContext}
                    dangerouslySetInnerHTML={{ __html: event.context ? event.context : 'No additional information' }}
                />

            </div>
        </>
    );
};

export default EventDetail;

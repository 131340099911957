import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StarBanking = () => {
  const onPopKBAuthMark = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    window.open(
      "",
      "KB_AUTHMARK",
      "height=604, width=648, status=yes, toolbar=no, menubar=no, location=no"
    );

    const KB_AUTHMARK_FORM = document.createElement("form");
    KB_AUTHMARK_FORM.method = "get";
    KB_AUTHMARK_FORM.action = "https://okbfex.kbstar.com/quics";
    KB_AUTHMARK_FORM.target = "KB_AUTHMARK";
    KB_AUTHMARK_FORM.submit();

    const fields = [
      { name: "page", value: "C021590" },
      { name: "cc", value: "b034066:b035526" },
      { name: "mHValue", value: "f881d283ae8d4abfa729cea6c41fc8b7" },
    ];

    fields.forEach(({ name, value }) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = name;
      input.value = value;
      KB_AUTHMARK_FORM.appendChild(input);
    });

    document.body.appendChild(KB_AUTHMARK_FORM);
    KB_AUTHMARK_FORM.submit();

    document.body.removeChild(KB_AUTHMARK_FORM);
  };

  return (
    <a href="#" onClick={onPopKBAuthMark}>
      <img
        src="http://img1.kbstar.com/img/escrow/escrowcmark.gif"
        alt="auth mark"
        style={{ width: "50px", height: "50px" }}
      />
    </a>
  );
};

export default StarBanking;

import React, { useState } from "react";
import axios from "axios";
import style from "./Register.module.scss";
import { useNavigate } from "react-router-dom";

const API = process.env.REACT_APP_API;

const Register = () => {
  const [name, setName] = useState("");
  const [emailAdress, setEmailAdress] = useState("");
  const [emailSite, setEmailSite] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [isEmailCodeVerified, setISEmailCodeVerified] = useState(false);
  const [pw, setPw] = useState("");
  const [verifiedPw, setVerifiedPw] = useState("");
  const [phoneNumberFirstDigit, setPhoneNumberFirstDigit] = useState("010");
  const [phoneNumberSecondDigit, setPhoneNumberSecondDigit] = useState("");
  const [phoneNumberLastDigit, setPhoneNumberLastDigit] = useState("");
  const [isPwVisible, setIsPwVisible] = useState(false);
  const [isPwCheckVisible, setIsPwCheckVisible] = useState(false);
  const [personalInfoConsent, setPersonalInfoConsent] = useState(true);
  const [marketingConsent, setmarketingConsent] = useState(false);
  const [isPageDown, setIsPageDown] = useState(false);

  const navigate = useNavigate();

  // 인증 메일 보내기
  const VerifyEmailReceive = async () => {
    if (emailAdress === "") {
      alert("이메일을 입력해주세요.");
      setEmailAdress("");
      return;
    }
    if (emailSite === "") {
      alert("이메일을 확인해주세요.");
      setEmailSite("");
      return;
    }
    try {
      const response = axios
        .post(
          `${API}/open-api/send-mail/email`,
          {
            email: `${emailAdress}@${emailSite}`,
          },
          {
            withCredentials: true,
          }
        )
        .then(() => {
          alert("인증 메일이 발송되었습니다. 인증 코드를 입력하세요.");
        })
        .catch((err) => {
          const errorMessage = err.response.data.result.resultDescription;
          if (errorMessage != "") {
            alert(errorMessage);
            //alert("이미 가입에 사용된 이메일입니다. 다른 계정으로 시도해주세요.");로 에러메세지를 바꿀 수 있나?
          } else {
            alert("사용할 수 없는 이메일입니다.");
          }
        });
    } catch (err) {}
  };

  // 인증 코드 확인
  const VerifyCode = async () => {
    try {
      axios
        .post(
          `${API}/open-api/send-mail/auth`,
          {
            code: emailCode,
            email: `${emailAdress}@${emailSite}`,
          },
          {
            withCredentials: true,
          }
        )
        .then(() => {
          setISEmailCodeVerified(true);
          alert("인증이 완료되었습니다. 회원가입을 마무리 해주세요.");
        })
        .catch(() => {
          alert("인증 코드를 확인하세요.");
        });
    } catch (err) {}
  };

  // 회원 가입 하기
  const RegisterSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isEmailCodeVerified == false) {
      alert("이메일을 인증해주세요.");
      setEmailCode("");
      return;
    }

    if (!/[\W_]/.test(pw) || /^[a-z0-9_-]{8,16}$/.test(pw)) {
      alert("비밀번호는 특수문자 포함, 영어 소문자 8~16자로 이루어져야합니다.");
      return;
    }

    if (pw !== verifiedPw) {
      alert("비밀 번호를 확인해주세요.");
      setVerifiedPw("");
      return;
    }
    if (
      !/^\d+$/.test(phoneNumberSecondDigit) ||
      !/^\d+$/.test(phoneNumberLastDigit)
    ) {
      alert("전화 번호를 다시 확인해주세요.");

      setPhoneNumberSecondDigit("");
      setPhoneNumberLastDigit("");
      return;
    }
    if (personalInfoConsent === false) {
      alert("개인 정보 수집 및 이용에 동의해주세요.");
    }
    const phoneNumber = `010-${phoneNumberSecondDigit}-${phoneNumberLastDigit}`;
    const Data = {
      name: name,
      email: `${emailAdress}@${emailSite}`,
      password: pw,
      phone_number: phoneNumber,
    };

    try {
      const response = await axios
        .post(`${API}/open-api/user/sign-up-v2`, Data, {
          withCredentials: true,
        })
        .then(() => {
          alert("회원가입이 완료되었습니다.");
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
          alert("회원가입 형식을 확인해주세요.");
        });
    } catch (err) {}
  };

  const personalInfoConsentCheck = () => {
    setPersonalInfoConsent(!personalInfoConsent);
  };

  return (
    <div className={style.register}>
      <h1 className={style.title__register}>Join Us</h1>
      <form onSubmit={RegisterSubmit} className={style.form__register}>
        <h2 className={style.title__form}>필수 입력 정보</h2>
        <hr />
        <div className={style.labels}>
          <label className={style.label}>
            <div>
              <span className={style.title__label}>이름</span>
            </div>
            <input
              className={style.input}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="이름을 입력해주세요."
              required
            />
          </label>
          <hr />
          <label className={`${style.label} & ${style.email_label}`}>
            <div>
              <span className={style.title__label}>이메일</span>
            </div>
            <div className={style.email_container}>
              <input
                className={`${style.input} & ${style.input__email}`}
                type="text"
                value={emailAdress}
                onChange={(e) => setEmailAdress(e.target.value)}
                placeholder=""
                required
              />
              <span>@</span>
              <input
                className={`${style.input} & ${style.input__email_site}`}
                type="text"
                value={emailSite}
                onChange={(e) => setEmailSite(e.target.value)}
                placeholder={emailSite}
                required
              />
              <select
                className={`${style.input} & ${style.select__email}`}
                value={emailSite}
                onChange={(e) => setEmailSite(e.target.value)}
              >
                <option value="none" selected>
                  직접 입력
                </option>
                <option>naver.com</option>
                <option>gmail.com</option>
                <option>hanmail.net</option>
                <option>nate.com</option>
                <option>kakao.com</option>
              </select>
              <button
                className={`${style.email_btn} & ${style.ivory_bg}`}
                type="button"
                onClick={VerifyEmailReceive}
              >
                인증번호받기
              </button>
              <input
                className={`${style.input} & ${style.input__email_code}`}
                type="text"
                value={emailCode}
                onChange={(e) => setEmailCode(e.target.value)}
                placeholder=""
                required
              />
              <button
                className={`${style.email_btn} & ${style.brown_bg}`}
                type="button"
                onClick={VerifyCode}
              >
                인증하기
              </button>
            </div>
          </label>

          <hr />
          <label className={`${style.label} & ${style.no_margin_bottom}`}>
            <div>
              <span className={style.title__label}>비밀번호</span>
            </div>
            <input
              className={style.input}
              type={isPwVisible ? "text" : "password"}
              value={pw}
              onChange={(e) => setPw(e.target.value)}
              pattern="^[a-z\d!@*&-_]{8,16}$"
              minLength={8}
              placeholder=""
              required
            />
            <button
              type="button"
              onClick={() => setIsPwVisible(!isPwVisible)}
              className={style.visibility__btn}
            >
              {isPwVisible ? (
                <img
                  src="./img/icons/visible.png"
                  className={style.visibility__icon}
                />
              ) : (
                <img
                  src="./img/icons/invisible.png"
                  className={style.visibility__icon}
                />
              )}
            </button>
          </label>
          <label className={`${style.label} & ${style.less_margin_top}`}>
            <div>
              <span className={style.title__label}>비밀번호 확인</span>
            </div>
            <input
              className={style.input}
              type={isPwCheckVisible ? "text" : "password"}
              value={verifiedPw}
              onChange={(e) => setVerifiedPw(e.target.value)}
              placeholder=""
              required
            />
            <button
              type="button"
              onClick={() => setIsPwCheckVisible(!isPwCheckVisible)}
              className={style.visibility__btn}
            >
              {isPwCheckVisible ? (
                <img
                  src="./img/icons/visible.png"
                  className={style.visibility__icon}
                />
              ) : (
                <img
                  src="./img/icons/invisible.png"
                  className={style.visibility__icon}
                />
              )}
            </button>
          </label>
          <hr />
          <label className={style.label}>
            <div>
              <span className={style.title__label}>전화번호</span>
            </div>
            <div className={style.phone_number}>
              <input
                className={style.input_phone}
                type="text"
                value={phoneNumberFirstDigit}
                onChange={(e) => setPhoneNumberFirstDigit(e.target.value)}
                maxLength={3}
                placeholder="010"
                required
              />
              -
              <input
                className={style.input_phone}
                type="text"
                value={phoneNumberSecondDigit}
                onChange={(e) => setPhoneNumberSecondDigit(e.target.value)}
                maxLength={4}
                placeholder=""
                required
              />
              -
              <input
                className={style.input_phone}
                type="text"
                value={phoneNumberLastDigit}
                onChange={(e) => setPhoneNumberLastDigit(e.target.value)}
                maxLength={4}
                placeholder=""
                required
              />
            </div>
          </label>
          <hr />
          <section className={style.consent}>
            <label>
              <div className={style.consent__header}>
                <input
                  className={style.consent_checkbox}
                  type="checkbox"
                  checked={personalInfoConsent}
                  onChange={personalInfoConsentCheck}
                  required
                />
                <span className={style.consent__title}>
                  개인 정보 수집 및 이용에 동의합니다.
                </span>
              </div>
              <label className={style.consent_content__btn}>
                <input type="checkbox" />
                개인 정보 수집 및 이용 전문 보기&#9660;
              </label>
              <div className={style.consent__wrapper}>
                <h1 className={style.consent__title}>
                  개인정보 수집 및 활용 동의서
                </h1>
                <p className={style.consent__p}>
                  ⌜개인정보보호법⌟ 제 15조 법규에 의거하여 매직클럽 코리아는
                  고객님의 개인정보 수집 및 활용에 대해 개인정보 수집 및 활용
                  동의서를 받고 있습니다.
                  <br />
                  개인 정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지
                  않으며, 제공된 개인정보의 이름을 거부하고자 할 때에는 개인정보
                  관리 책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.
                </p>

                <h1 className={style.consent__extra}>
                  ※ 제공된 개인정보는 매직클럽 코리아의 아래 항목에 제한된
                  범위에서만 활용합니다.
                </h1>
                <p className={style.consent__p}>
                  개인정보 항목: 성명, 연락처
                  <br />
                  <ul className={style.lists}>
                    개인정보수집 및 이용목적:
                    <li>
                      &#91;개인정보 수집 항목&#93; 필수항목: 성명, 휴대전화번호,
                      이메일 주소
                    </li>
                    <li>
                      &#91;개인정보 이용목적&#93; 매직클럽 코리아 멤버십 서비스
                      이용에 따른 본인 확인 행사 참여 시 본인 확인 절차에 이용
                      소비자 기본법 제 52조에 의거한 소비자를 위한 정보 수집
                    </li>
                    <li>
                      &#91;개인정보 보유 기간&#93; 회원 가입시 ~ 회원의 탈퇴 및
                      퇴장 요청 시
                    </li>
                    <li>
                      &#91;개인정보 수집 이용 거부&#93; 개인정보 수집 이용 거부
                      시 회원 가입이 불가능합니다.
                    </li>
                  </ul>
                </p>
                <p className={style.consent__p}>
                  ⌜개인정보보호법⌟ 제 15조 법규에 의거하여 상기 본인은 위와 같이
                  개인 정보 수집 및 활용에 동의함.
                </p>
              </div>
            </label>

            <label>
              <div className={style.consent__header}>
                <input className={style.consent_checkbox} type="checkbox" />
                <span className={style.consent__title}>
                  마케팅 정보 이용에 동의합니다.
                </span>
              </div>
              <label className={style.consent_content__btn}>
                <input type="checkbox" />
                마케팅 정보 이용 전문 보기&#9660;
              </label>
              <div className={style.consent__wrapper}>
                <h1 className={style.consent__title}>
                  마케팅 및 홍보 활용 동의서
                </h1>
                <p className={style.consent__p}>
                  매직클럽 코리아의 상품 안내, 이벤트 행사 관련 정보 안내
                  목적으로 이메일, SMS를 통한 상품 및 이벤트 정보 송신을 위해
                  이용하고자합니다.
                  <br />
                  마케팅 및 홍보에 활용을 원하지 않을 경우 동의하시지 않으셔도
                  됩니다.
                </p>

                <h1 className={style.consent__extra}>
                  ※ 제공된 개인정보는 매직클럽 코리아의 아래 항목에 제한된
                  범위에서만 활용합니다.
                </h1>
                <p className={style.consent__p}>
                  개인정보 항목: 성명, 연락처
                  <br />
                  <ul className={style.lists}>
                    개인정보수집 및 이용목적:
                    <li>
                      &#91;개인정보 수집 항목&#93; 필수항목: 성명, 휴대전화번호,
                      이메일 주소
                    </li>
                    <li>
                      &#91;개인정보 이용목적&#93; 매직클럽 코리아의 상품 행사
                      관련 정보 안내
                    </li>
                    <li>
                      &#91;개인정보 보유 기간&#93; 회원 가입시 ~ 회원의 탈퇴 및
                      퇴장 요청 시 또는 회원 삭제 요청시 까지
                    </li>
                    <li>
                      &#91;개인정보 수집 이용 거부&#93; 마케팅 및 홍보 활용 거부
                      시 상품 및 행사 정보를 받을 수 없습니다.
                    </li>
                  </ul>
                </p>
                <p className={style.consent__p}>
                  ⌜개인정보보호법⌟ 제 15조 법규에 의거하여 상기 본인은 위와 같이
                  개인 정보 수집 및 활용에 동의함.
                </p>
              </div>
            </label>
          </section>
          <div className={style.register_btn__container}>
            <button className={style.register_button} type="submit">
              회원 가입
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;

import React from "react";
import { Route, Routes } from "react-router-dom";
import EventDetail from "../eventDetail/EventDetail";
import EventPage from "../eventPage/EventPage";
import EventRegister from "../eventRegister/EventRegister";
import Login from "../login/Login";
import Main from "../main/Main";
import MyPage from "../myPage/MyPage";
import OwnerHealth from "../ownerHealth/OwnerHealth";
import OwnerPage from "../ownerPage/OwnerPage";
import OwnerUserInfo from "../ownerUserInfo/OwnerUserInfo";
import OwnerYoutube from "../ownerYoutube/OwnerYoutube";
import Password from "../password/Password";
import Register from "../register/Register";
import Rule from "../rule/Rule";
import KakaoTest from "../kakaoTest/KakaoTest";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/events" element={<EventPage />} />
      <Route path="/my-page" element={<MyPage />} />
      <Route path="/rule" element={<Rule />} />
      <Route path="/owner-page/:id/:num" element={<OwnerPage />} />
      <Route path="/owner-user-info" element={<OwnerUserInfo />} />
      <Route path="/events/:id" element={<EventDetail />} />
      <Route path="/owner-health" element={<OwnerHealth />} />
      <Route path="/owner-youtube" element={<OwnerYoutube />} />
      <Route path="/password" element={<Password />} />
      <Route path="/event-register/:id" element={<EventRegister />} />
      <Route path="/kakao-test" element={<KakaoTest />} />
    </Routes>
  );
}

export default Router;

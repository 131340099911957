import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "./slick_mainEvent.css";
import "./slick-theme_mainEvent.css";
import "../../_mixin.scss";
import style from "./MainEventSlider.module.scss"
import { useNavigate } from "react-router-dom";

interface Event {
  title: string;
  place: string;
  evented_at: string;
  img_url: string;
  category_id: number;
}

interface BannerSliderProps {
  eventInfo: Event[];
}

const calcVw = (px: number, base: number) => {
  return `${(px / base) * 100}vw`;
};
const breakpointMobile = "720px";
const breakpointContent = "1600px";
const headerHeight = "100px";

const EventContainer = styled.div`
  margin-top: 70px;
  width: 100%;
  min-height: 700px;
  position: relative;
  overflow: hidden;
  @media (max-width: 720px) {
    margin-top: 0;
    min-height: unset;
  }
  .slick-slider {
    height: 600px;
    @media (max-width: 720px) {
      height: unset;
    }
    .slick-list {
      width: 100%;

      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        .slick-slide {
        }
        .slick-current {
          div {
            height: 120%;
            @media (max-width: 720px) {
              height: 100%;
              margin-top: 0;
            }
          }
        }
      }
    }
  }
`;

const EventDetailContainer = styled.div`
  max-width: 580px;
  height: 450px;
  margin: 0 35vw;
  margin: 50px 0 100px 0;
  @media (max-width: 720px) {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0 !important;
    p {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding-left: 5%;
    }
  }
`;
const EventDetailImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: ${calcVw(420, 1600)};
    max-width: 420px;
    height: ${calcVw(420, 1600)};
    max-height: 420px;
    box-shadow: 0px 0px 25px 0px #000000;
  }
  @media (max-width: 720px) {
    img {
      width: 90%;
      height: 90%;
      max-width: none;
      max-height: none;
      box-shadow: 0px 0px 15px 0px #000000;
    }
  }
`;
const EventDetailTitle = styled.p`
  margin-top: 40px;
  font-family: "Pretendard Variable", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #ffffff;
  @media (max-width: 720px) {
    margin-top: 20px;
    font-size: 24px;
  }
`;
const EventDetailSubTitle = styled.p`
  margin-top: 20px;
  font-family: "Pretendard Variable", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #ffffff;
  @media (max-width: 720px) {
    margin-top: 5px;
    font-size: 20px;
  }
`;
const EventDetailDate = styled.p`
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #aba493;
  @media (max-width: 720px) {
    margin-top: 5px;
    font-size: 16px;
  }
`;

const ArrowBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  // top: auto;
  // bottom: 0;
  // transform: translate(0, 0);
`;

const ArrowIcon = styled.img`
  width: 65%;
  height: 65%;
`;

function NextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={`${className} ${style.rArrow}`} onClick={onClick}>
      <ArrowIcon src="../img/icons/rarrow.png" />
    </div>
  );
}

function PrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={`${className} ${style.lArrow}`} onClick={onClick}>
      <ArrowIcon src="../img/icons/larrow.png" />
    </div>
  );
}

const MainEventSlider: React.FC<BannerSliderProps> = ({ eventInfo }) => {
  const navigate = useNavigate();

  function showEventDetail(id: number){
    navigate(`/events/${id}`)
  }
  
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <NextArrow></NextArrow>,
    prevArrow: <PrevArrow></PrevArrow>,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <EventContainer className="slider-container">
      <Slider {...settings}>
        {eventInfo.map((event) => (
          <EventDetailContainer key={event.title} onClick={() => {showEventDetail(event.category_id)}}>
            <EventDetailImg>
              <img src={`./img/${event.img_url}`}  style={{ cursor: 'pointer' }}/>
            </EventDetailImg>
            <EventDetailTitle style={{ cursor: 'pointer' }}>{event.title}</EventDetailTitle>
            <EventDetailSubTitle style={{ cursor: 'pointer' }}>{event.place}</EventDetailSubTitle>
            <EventDetailDate style={{ cursor: 'pointer' }}>{event.evented_at}</EventDetailDate>
          </EventDetailContainer>
        ))}
      </Slider>
    </EventContainer>
  );
};

export default MainEventSlider;

import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "./OwnerHealth.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/authContext/AuthContext";

const API = process.env.REACT_APP_API;

interface InfoResponse {
  body: {
    email: string;
    name: string;
    user_state: string;
  };
  result: string;
}

interface EventList {
  body: Event[];
  result: string;
}

interface Event {
  names: string;
  phone_number: string;
  email: string;
}

const OwnerHealth = () => {
  const navigate = useNavigate();
  const [isLend, setIsLend] = useState(false);
  const [health, setHealth] = useState<string>("");

  useEffect(() => {
    const getInfo = axios.get<InfoResponse>(`${API}/api/user/me`, {
      withCredentials: true,
    });
    getInfo
      .then((data) => {
        console.log(data);
        if (data.status == 200) {
          if(data.data.body.user_state != "OWNER") navigate("/");
          setIsLend(true);
        }
      })
      .catch((e) => {
        navigate("/");
      });
  }, []);

  useEffect(() => {
    if(isLend == false) return;
    const getInfo = axios.get<string>(`${API}/open-api/user/health`, {
      withCredentials: true,
    });
    getInfo.then((data) => {
      setHealth(data.data);
    })
  }, [isLend]);

  return (
    <div className={style.login}>
      {
        !isLend ? <div></div> : (
          <div className="no">
            {health}
          </div>
        )
      }
    </div>
  );
};

export default OwnerHealth;

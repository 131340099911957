import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "./Login.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../components/authContext/AuthContext";

const API = process.env.REACT_APP_API;

interface LoginResponse {
  body: {
    email: string;
    name: string;
  };
  result: string;
}

interface UserInfoResponse {
  name: string;
  email: string;
}

const Login = () => {
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [isPwVisible, setIsPwVisible] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const Kakao = window.Kakao;
  const KAKAO = process.env.REACT_APP_KAKAO;
  const API = process.env.REACT_APP_API;
  const DOMAIN = process.env.REACT_APP_DOMAIN;

  useEffect(() => {
    if(!Kakao.isInitialized()){
      Kakao.init(KAKAO);
    }
  }, []);

  useEffect(() => {
    handleCallback();
  }, []);

  function _login(){
    if(window.Kakao){
      Kakao.Auth.authorize({
        redirectUri: `${DOMAIN}/login`
      });
    }
  }

  const handleCallback = async () => {
    // URLSearchParams를 사용하여 URL에서 쿼리 파라미터 추출
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log(code);

    if (code) {
      try {
        console.log(code)
        // 백엔드로 인가 코드를 전달하여 로그인 처리
        const response = await axios.post(`${API}/open-api/oauth`, {
          code
        }, { withCredentials: true });

        console.log(response)

        if (response.data.result.resultCode == 200) {
          login();
          navigate('/'); // 메인 페이지로 이동
        } else {
          alert('로그인 실패: ' + response.data.message);
        }
      } catch (error) {
        console.error('로그인 오류:', error);
        alert('로그인 중 오류가 발생했습니다.');
      }
    }
  };

  const LoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = axios
        .post<LoginResponse>(
          `${API}/open-api/user/login`,
          {
            email: email,
            password: pw,
          },
          { withCredentials: true }
        )
        .then(async (data) => {
          const userInfo: UserInfoResponse = data.data.body;

          login();
          navigate("/");
        })
        .catch((e) => {
          alert("이메일과 비밀번호를 다시 확인해 주세요");
        });
    } catch (err) {}
  };

  return (
    <div className={style.login}>
      <h1 className={style.title}>Login</h1>
      <form className={style.form} onSubmit={LoginSubmit}>
        <label className={style.label}>
          <span className={style.label__span}>이메일</span>
          <input
            className={style.input}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="이메일을 입력해주세요"
            required
          />
        </label>
        <label className={style.label}>
          <span className={style.label__span}>비밀번호</span>
          <input
            className={style.input}
            type={isPwVisible ? "text" : "password"}
            value={pw}
            onChange={(e) => setPw(e.target.value)}
            placeholder="비밀번호를 입력해주세요"
            required
          />
          <button
            type="button"
            onClick={() => setIsPwVisible(!isPwVisible)}
            className={style.visibility__btn}
          >
            {isPwVisible ? (
              <img
                src="./img/icons/visible.png"
                className={style.visibility__icon}
              />
            ) : (
              <img
                src="./img/icons/invisible.png"
                className={style.visibility__icon}
              />
            )}
          </button>
        </label>
        <div className={style.options}>
          <Link className={style.option} to={"/#"}>
            이메일 찾기
          </Link>
          |
          <Link className={style.option} to={"/password"}>
            비밀번호 찾기
          </Link>
          |
          <Link className={style.option} to={"/register"}>
            회원가입
          </Link>
        </div>
        <button className={style.login_btn} type="submit">
          로그인
        </button>
        <hr className={style.hr}/>
        <div className={style.kakaoLogin}>
          <button className={style.btnKakao} type="button" onClick={_login}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon icon--logo">
              <path d="M11.9982 4C6.61144 4 2.245 7.4427 2.245 11.69C2.245 14.436 4.07091 16.848 6.81682 18.206C6.66771 18.7208 5.8565 21.5202 5.82462 21.7406C5.82462 21.7406 5.80492 21.9056 5.91183 21.9684C5.9476 21.9865 5.98668 21.997 6.02667 21.9995C6.06666 22.0019 6.10673 21.9961 6.14441 21.9825C6.45201 21.9394 9.70809 19.6558 10.2708 19.2629C10.8431 19.3423 11.4204 19.3815 11.9982 19.3801C17.385 19.3801 21.7515 15.9374 21.7515 11.69C21.7515 7.4427 17.385 4 11.9982 4Z" fill="black" fill-opacity="0.9"></path>
            </svg>
            카카오 로그인
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "./OwnerUserInfo.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/authContext/AuthContext";

const API = process.env.REACT_APP_API;

interface InfoResponse {
  body: {
    email: string;
    name: string;
    user_state: string;
  };
  result: string;
}

interface EventList {
  body: Event[];
  result: string;
}

interface Event {
  names: string;
  phone_number: string;
  email: string;
}

const OwnerUserInfo = () => {
  const {isLogin, logout} = useAuth();
  const navigate = useNavigate();
  const [isLend, setIsLend] = useState(false);
  const [eventList, setEventList] = useState<Event[]>([]);
  const { id, num } = useParams<{ id: string, num: string }>();

  useEffect(() => {
    const getInfo = axios.get<InfoResponse>(`${API}/api/user/me`, {
      withCredentials: true,
    });
    getInfo
      .then((data) => {
        console.log(data);
        if (data.status == 200 && !(API?.includes("magicclub"))) {
          setIsLend(true);
        }
      })
      .catch((e) => {
        navigate("/");
      });
  }, []);

  function remove(){
    const getInfo = axios.delete<string>(`${API}/api/user`, {
      withCredentials: true,
    });
    getInfo.then((data) => {
      alert("DELETE SUCCESS");
      logout()
      navigate('/')
    })
  }

  function setOwner(state: string){
    const getInfo = axios.post<string>(`${API}/api/user/change-state`, {
      user_state: 3
    }
    ,{
      withCredentials: true,
    });
    getInfo.then((data) => {
      alert("SET SUCCESS");
    })
  }

  return (
    <div className={style.login}>
      {
        !isLend ? <div></div> : (
          <div className="no">
              <button onClick={remove}>REMOVE</button>
              <button onClick={() => {setOwner("NORMAL")}}>setOwner</button>
          </div>
        )
      }
    </div>
  );
};

export default OwnerUserInfo;

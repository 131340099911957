import React, { useEffect, useState } from "react";
import style from "./Main.module.scss";
import axios from "axios";
import BannerSlider from "../../components/bannerSlider/BannerSlider";
import MainEventSlider from "../../components/mainEventSlider/MainEventSlider";
import { ReactComponent as YoutubeIcon } from "./youtubeIcon.svg";

interface VideoResponse {
  body: Video[];
  result: string;
}

interface Video {
  youtube_id: string;
  published_at: string;
  title: string;
}

interface Event {
  title: string;
  place: string;
  evented_at: string;
  img_url: string;
  category_id: number;
}

const Main = () => {
  const [videoInfo, setVideoInfo] = useState<Video[]>([]);
  const [event, setEvent] = useState<Event[]>([]);

  const promotionId = "5qcoylQzFNI";
  const API = process.env.REACT_APP_API;
  const YOUTUBE_KEY = process.env.REACT_APP_YOUTUBE_API;

  const banner_count = 8;
  function calcBannerPos(pos: number): string {
    return pos < 0
      ? (pos + banner_count).toString()
      : pos >= banner_count
      ? (pos - banner_count).toString()
      : pos.toString();
  }

  function unescapeHtml(str: string) {
    if (str == null) {
      return "";
    }
    return str
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .replace(/&#39;/g, "'");
  }

  useEffect(() => {
    const response = axios
      .get<VideoResponse>(`${API}/open-api/simple-youtube`)
      .then((data) => {
        console.log(data.data.body);

        data.data.body.map((it: Video) => {
          const tmp = new Date(it.published_at);
          it.published_at = `${tmp.getFullYear()}.${String(
            tmp.getMonth() + 1
          ).padStart(2, "0")}.${String(tmp.getDate()).padStart(2, "0")}`;
        });

        setVideoInfo(data.data.body);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    const response = axios
      .get(`${API}/open-api/event/all`)
      .then(async (data) => {
        console.log(data);
        data.data.body.map((it: Event) => {
          const tmp = new Date(it.evented_at);
          it.evented_at = `${tmp.getFullYear()}.${String(
            tmp.getMonth() + 1
          ).padStart(2, "0")}.${String(tmp.getDate()).padStart(2, "0")}`;
        });
        console.log(data.data.body);
        setEvent(data.data.body);
        console.log(event);
      })
      .catch((e) => {});
  }, []);

  return (
    <div className={style.main}>
      <section className={style.banner}>
        <BannerSlider />
      </section>

      <div className={style.context}>
        <div className={style.slogan}>
          <div className={style.sloganText}>
            <p className={style.sloganSubTitle}>
              with magic, with joy, with us
            </p>
            <p className={style.sloganTitle}>
            마술 놀이터, 매직클럽
            </p>
            <p className={style.sloganText}>
            마술을 사랑하는 사람들이 모여 함께 만들어가는 매직클럽입니다.<br/>
            <br/>
            우리가 마술을 좋아하는 이유는 순수함 때문일겁니다.<br/>
            오늘날 마술은 단순한 오락이나 유희의 대상이 아닙니다.<br/>
            신비함이 실제라고 믿고 싶어하는 인간의 욕망과 감각 그리고 탐구심이 끝없이 이어지는 문화입니다.<br/>
            <br/>
            사람이 모여 사회를 이루고 문화가 탄생합니다.<br/>
            매직클럽 코리아는 건강한 한국의 마술 문화에 이바지하고<br/>
            나아가 세계 시장에 선한 영향력을 미치게 되길 희망합니다.<br/>
            <br/>
            ‘매직클럽 코리아’는<br/>
            서로 다른 사람들이 모여<br/>
            ‘마술’로 하나되어 함께할 수 있는<br/>
            즐거운 놀이터를 만드는 것을 최우선으로 하겠습니다.<br/>
            </p>
          </div>
          <div className={style.showImg}>
            <img src={"./img/etc/mainImg.png"} alt="" className={style.img} />
            <img src={"./img/etc/magicHat.png"} alt="" className={style.hat} />
          </div>
        </div>

        <div className={style.youtube}>
          <p className={style.youtubeSubTitle}>Youtube</p>
          <p className={style.youtubeTitle}>최신 유튜브 콘텐츠</p>
          <a
            href="https://www.youtube.com/@MagicClubKorea"
            className={style.youtubeUrl}
          >
            <span className={style.youtubeContainer}>
              <YoutubeIcon />
            </span>
            <p className={style.youtubeUrlText1}>매직클럽 유튜브 </p>
            <p className={style.youtubeUrlText2}>바로가기</p>
            <svg
              width="15"
              height="12"
              viewBox="0 0 15 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8167 6.42448L9.19189 11.8242C9.07462 11.9368 8.91556 12 8.74971 12C8.58387 12 8.42481 11.9368 8.30754 11.8242C8.19027 11.7116 8.12439 11.5589 8.12439 11.3997C8.12439 11.2405 8.19027 11.0878 8.30754 10.9752L12.866 6.59997H0.62498C0.459225 6.59997 0.300259 6.53676 0.183052 6.42424C0.065846 6.31172 0 6.15912 0 6C0 5.84088 0.065846 5.68827 0.183052 5.57576C0.300259 5.46324 0.459225 5.40003 0.62498 5.40003H12.866L8.30754 1.02478C8.19027 0.912198 8.12439 0.75951 8.12439 0.6003C8.12439 0.441091 8.19027 0.288402 8.30754 0.175824C8.42481 0.0632457 8.58387 0 8.74971 0C8.91556 0 9.07462 0.0632457 9.19189 0.175824L14.8167 5.57552C14.8748 5.63124 14.9209 5.69741 14.9524 5.77025C14.9838 5.84308 15 5.92115 15 6C15 6.07884 14.9838 6.15692 14.9524 6.22975C14.9209 6.30259 14.8748 6.36876 14.8167 6.42448Z"
                fill="black"
              />
            </svg>
          </a>
          <div className={style.youtubeList}>
            {videoInfo.map((info) => (
              <div className={style.youtubeBox}>
                <a
                  href={"https://www.youtube.com/watch?v=" + info.youtube_id}
                  className={style.img}
                >
                  <img
                    src={
                      "https://img.youtube.com/vi/" + info.youtube_id + "/0.jpg"
                    }
                    alt=""
                    className={style.img}
                  />
                </a>
                <p className={style.youtubeBoxTitle}>
                  {unescapeHtml(
                    info.title.length > 20
                      ? `${info.title.slice(0, 20)}...`
                      : info.title
                  )}
                </p>
                <p className={style.youtubeBoxDate}>{info.published_at}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.event}>
        <div className={style.eventContainer}>
          <div className={style.eventTitleArea}>
            <p className={style.eventSubTitle}>Our Event</p>
            <p className={style.eventTitle}>이벤트</p>
          </div>
          {event.length == 0 ? (
            <>LOADING....</>
          ) : (
            <div className={style.eventSliderContainer}>
              <MainEventSlider eventInfo={event} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Main;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import style from "./EventPage.module.scss";

interface Event {
    id: number;
    title: string;
    place: string;
    evented_at: string;
    finished_at: string;
    close_min: number;
    close_max: number;
    money: number;
    img_url: string;
    category_id: number;
}

const EventList: React.FC = () => {
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const API = process.env.REACT_APP_API;
                const response = await axios.get<{ result: any, body: Event[] }>(`${API}/open-api/event/all`);
                setEvents(response.data.body);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch events');
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const isEventExpired = (dateString: string) => {
        const eventDate = new Date(dateString);
        const today = new Date();
        return eventDate < today;
    };

    const formatMoney = (amount: number) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div className={style.eventList}>
            <div className={style.eventPageHeader}>
                <h1>매직클럽 이벤트</h1>
                <p>매직클럽에서 진행 중이거나 진행이 완료된 이벤트입니다.</p>
            </div>

            <div className={style.eventContent}>
                <div className={style.eventGrid}>
                    {events.length > 0 ? (
                        events.map(event => (
                            <div className={style.boxContainer}>
                                <div key={event.id} className={style.eventItem}>
                                    {isEventExpired(event.finished_at) && (
                                        <div className={style.eventExpired}>마감</div>
                                    )}
                                    <img src={`/img/${event.img_url}`} alt={event.title} className={style.eventImage} />
                                    <div className={style.eventDetails}>
                                        <h2 className={style.eventTitle}>{event.title}</h2>
                                        <div className={style.eventMeta}>
                                            <p>장소 <span className={style.space}>{event.place}</span></p>
                                            <p>날짜 <span>{`${new Date(event.evented_at).getFullYear()}. ${new Date(event.evented_at).getMonth()+1}. ${new Date(event.evented_at).getDate()}`}</span></p>
                                        </div>
                                        <Link to={`/events/${event.category_id}`} className={style.eventLink}>이벤트 자세히 보기</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No events available</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EventList;

import FooterPC from "./FooterPC";
import FooterMobile from "./FooterMobile";
import { useMediaQuery } from "react-responsive";
import React from "react";

const Footer = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 720px)" });
  return <>{isTabletOrMobile ? <FooterMobile /> : <FooterPC />}</>;
};

export default Footer;
